/**
 * 災害情報取得用モジュール
 * @module app/model/DisasterInfo
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/cookie',
    'dojo/topic',
    'idis/service/Requester'
], function(module, declare, lang, cookie, topic, Requester) {
    /**
     * 災害情報を管理するオブジェクト
     */
    return new declare(null, {

        /**
         * 災害情報に対するリクエスト結果
         * @type {Promise<Object>}
         */
        _promise: null,

        /**
         * 災害情報
         * @type {Object}
         */
        _disasterId: null,

        /**
         * 大阪府災害情報
         * @type {Object}
         */
        _extraDisasterId: null,

        /**
         * 災害種別
         * @type {Object}
         */
        _disasterType: null,

        /**
         * 災害モード宣言
         * @type {Object}
         */
        _declareTimestamp: null,

        /**
         * 発生期間（開始）
         * @type {Object}
         */
        _startTimestamp: null,

        load: function() {
            if (!this._promise) {
                this._promise = Requester.get('/api/disasters/disaster4Cookie').then(lang.hitch(this, function() {
                    this._disasterId = cookie('DISASTER_ID');
                    this._extraDisasterId = cookie('EXTRA_DISASTER_ID');
                    this._disasterType = cookie('DISASTER_TYPE');
                    this._declareTimestamp = cookie('DECLARE_TIMESTAMP');
                    this._startTimestamp = cookie('START_TIMESTAMP');
				}));
            }
            return this._promise;
        },

        constructor: function() {
            this.inherited(arguments);
            // 災害名登録後に呼ばれる
            topic.subscribe('app/disaster/view/DisasterAdminPage::registered',
                lang.hitch(this, function(payload) {
                    this.setDisasterId(payload);
            }));
        },

        /**
         * 災害情報をCookieから取得する。
         */
        getDisasterId: function() {
            this._disasterId = cookie('DISASTER_ID');
            return this._disasterId;
        },

        /**
         * 災害情報をCookieにセットする
         * @returns {string} 識別子
         */
        setDisasterId: function(id) {
            this._disasterId = id;
            cookie('DISASTER_ID', id);
        },

        /**
         * 大阪府災害情報をCookieから取得する。
         */
        getExtraDisasterId: function() {
            this._extraDisasterId = cookie('EXTRA_DISASTER_ID');
            return this._extraDisasterId;
        },

        /**
         * 大阪府災害情報をCookieにセットする
         * @returns {string} 識別子
         */
        setExtraDisasterId: function(id) {
            this._extraDisasterId = id;
            cookie('EXTRA_DISASTER_ID', id);
        },

        /**
         * 災害種別をCookieから取得する。
         */
         getDisasterType: function() {
            this._disasterType = cookie('DISASTER_TYPE');
            return this._disasterType;
        },

        /**
         * 災害種別をCookieにセットする
         * @returns {string} 識別子
         */
        setDisasterType: function(type) {
            this._disasterType = type;
            cookie('DISASTER_TYPE', type);
        },

        /**
         * 災害モード宣言をCookieから取得する。
         */
        getDeclareTimestamp: function() {
            this._declareTimestamp = cookie('DECLARE_TIMESTAMP');
            return this._declareTimestamp;
        },

        /**
         * 災害モード宣言をCookieにセットする
         * @returns {string} 識別子
         */
        setDeclareTimestamp: function(timestamp) {
            this._declareTimestamp = timestamp;
            cookie('DECLARE_TIMESTAMP', timestamp);
        },

        /**
         * 発生期間（開始）をCookieから取得する。
         */
         getStartTimestamp: function() {
            this._startTimestamp = cookie('START_TIMESTAMP');
            return this._startTimestamp;
        },

        /**
         * 発生期間（開始）をCookieにセットする
         * @returns {string} 識別子
         */
        setStartTimestamp: function(timestamp) {
            this._startTimestamp = timestamp;
            cookie('START_TIMESTAMP', timestamp);
        }

    })();
});
