/**
 * 観測情報表示用ラスターレイヤー
 * 画像タイルで構成されるレイヤーを時系列で表示できるようにするもの
 * @module app/observation/map/ObservationRasterLayer
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/date/locale',
    'dojo/promise/all',
    'dojox/lang/functional',
    'leaflet',
    'idis/service/Requester',
    'idis/consts/QUERY',
    'idis/control/Locator',
    'app/observation/LatestData',
    'app/observation/define/TileUrl'
], function(module, array, declare, lang, Deferred, locale, all, df,
    leaflet, Requester, QUERY, Locator, LatestData, TileUrl) {

    var observationRasterLayer = leaflet.TileLayer.extend({

        statusUrl: null,        // 可変となるURL
        directorySuffix: null,  // タイルが置いてあるディレクトリ名のsuffix
        timespan: 10,           // 観測情報の発生時刻間隔

        _lastQuery: {},
        _latestDataTimestamp: null,  // 観測データの最新時刻を保管
        _handle: null, // Locatorを監視するハンドラ

        _checkTileUrl: TileUrl,

        // コンストラクタ
        initialize: function(layerInfo, options) {

            this.statusUrl = layerInfo.layerUrl;
            this.directorySuffix = layerInfo.directorySuffix || '';
            this.timespan = layerInfo.timespan || this.timespan;

            var latestData = new LatestData({url: this.statusUrl + '/latest.json'});

            // 初期表示
            // superの呼び出し
            leaflet.TileLayer.prototype.initialize.call(
                this, '', options);

            all({
                // データの最新更新情報を取得
                latest: latestData.load()

            }).then(lang.hitch(this, function() {
                var timestamp = latestData.getLatestDateTimestamp();
                var timestampWithDash = timestamp.replace(
                    /(\d{4}-\d{2}-\d{2}) (\d{2}):(\d{2}):\d{2}/, '$1-$2-$3');

                // タイルができているかを確認
                this._latestTileTimestamp(timestampWithDash).then(lang.hitch(this, function(tileTimestamp) {
                    // YYYY-mm-DD HH:MM:ss -> YYYY-mm-DD-HH-MM
                    this._latestDataTimestamp = tileTimestamp;
                    this._refresh(this._latestDataTimestamp);

                    this._lastQuery = Locator.getQuery();

                    // URLの変更を監視
                    this._handle = Locator.on('change', lang.hitch(this, this.onLocationChanged));
                }));
            }));
        },

        // タイルが存在する最新時刻を探す
        // latestDataから取得した時刻のディレクトリにはまだタイルが生成されていない場合がある
        // その場合は1つ前の時間を返すようにする
        // 1つ前の時間については存在チェックは行わない, その場合はタイルへのアクセスが404になる
        _latestTileTimestamp: function(timestamp) {
            // latestDataの時間からタイルを探す
            var directory = this._layerDirectoryName(timestamp);
            // レベル11のタイルが入っているディレクトリを対象にする
            var urlPrefix = this.statusUrl + '/' + directory + this.directorySuffix;
            var dfd = new Deferred();

            // 画像リクエストなので、encodingとhandleAsをこう設定しないと画像があってもerrorに飛んでしまう
            all(
                array.map(this._checkTileUrl, function(url) {
                    return Requester.get(urlPrefix + url, { encoding: null, handleAs: undefined });
                })
            ).then(lang.hitch(this, function() {
                dfd.resolve(timestamp);

            }), lang.hitch(this, function() {
                // timespan(分)だけさかのぼったtimestampを戻す
                dfd.resolve(this.minusTimespan(timestamp));
            }));

            return dfd.promise;
        },

        // yyyy-mm-dd-hh-mm形式の時刻について,
        // Timespan(分)でしていされた時間を引いて同じ形式の文字列にしたものを戻す
        minusTimespan: function(timestamp) {
            var splitted = timestamp.split('-');
            var date = new Date(splitted[0], splitted[1]-1, splitted[2], splitted[3], splitted[4]);

            // 時刻がUTCになるから9時間進めつつTimespanを引く
            // date.setHours(date.getHours() + 9);
            date.setMinutes(date.getMinutes() - this.timespan);

            return locale.format(date, {
                datePattern: 'yyyy-MM-dd',
                timePattern: 'HH-mm'
            }).replace(/ /g, '-');
        },

        // URL変更時の処理
        // 時刻が変わったら観測情報を取得してレイヤーを更新
        onLocationChanged: function() {
            // 現在のクエリー情報を取得
            var query = Locator.getQuery();

            // 緯度経度、レイヤー、ズームレベルが変更された場合はデータの更新はないので何もしない
            // 最新日時が選択されている状態で「最新」ボタンをクリックすると、日時は変わっていないがデータが
            // 更新されている可能性があるので、日付の一致は確認しない
            if (query[QUERY.LATLNG] !== this._lastQuery[QUERY.LATLNG]) {
                this._lastQuery = query;
                return;
            }
            if (query[QUERY.LAYER_LIST] !== this._lastQuery[QUERY.LAYER_LIST]) {
                this._lastQuery = query;
                return;
            }
            if (query[QUERY.ZOOM] !== this._lastQuery[QUERY.ZOOM]) {
                this._lastQuery = query;
                return;
            }

            // 日時や時間モードが指定されてなかった時のデフォルト値
            var datetime = query.datetime ? query.datetime : this._latestDataTimestamp;
            // レイヤーを更新
            this._refresh(datetime);

            // 次の更新に備えてクエリー状態を保存
            this._lastQuery = query;
        },

        // レイヤー内容を指定された時間の観測情報で更新
        _refresh: function(timestamp) {
            var directoryName = this._layerDirectoryName(timestamp);

            // datetimeで表されるタイルURLを取得
            var url = this.statusUrl + '/' + directoryName + this.directorySuffix + '/{z}/{x}/{y}.png';
            this.setUrl(url);
        },

        // timestamp文字列 (yyyy-mm-dd-hh-mm) を esriが出力しているレイヤーディレクトリ (yymmddhhmm) に変換
        _layerDirectoryName: function(timestamp) {
            return timestamp.replace(/(\d{4})-(\d{2})-(\d{2})-(\d{2})-(\d{2})/, '$1$2$3$4$5');
        }
    });

    return observationRasterLayer;
});
