/**
 * 背景地図情報一覧を定義。
 * @module app/map/baselayer/BaseLayerOptions
 */
define([
    'dojo/text!./templates/esriAttribution.html',
    // 'dojo/text!./templates/zenrinAttribution.html',
    'dojo/text!./templates/gsiAttribution.html'
// ], function(esriAttrTemplate, zenrinAttrTemplate, gsiAttrTemplate) {
], function(esriAttrTemplate, gsiAttrTemplate) {
    return [
        {
            // ESRI提供の道路地図
            id: 'ESRI_ST',
            label: '道路地図',
            apiKey: process.env.ARCGIS_API_KEY,
            itemId: '0fb0ac10931043ba81bef4b2d64d7165', //日本語の道路地図
            styleId: 'ArcGIS:Streets',
            attribution: esriAttrTemplate,
            image: 'esri_st_vector.png'
        },{
            // 地理院提供の写真地図（インターネット経由で取得）
            id: 'GSI_PALE_NET',
            label: '淡色',
            url: '//cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
            attribution: gsiAttrTemplate,
            image: 'gsi_pale.png'
        }, {
            // 地理院提供の標準地図（インターネット経由で取得）
            id:'GSI_STANDARD_NET',
            label: '標準',
            url: '//cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png',
            attribution: gsiAttrTemplate,
            image: 'gsi_std.png'
        }, {
        //     // 地理院提供の写真地図（インターネット経由で取得）
        //     id: 'GSI_BLANK_NET',
        //     label: '白地図',
        //     url: '//cyberjapandata.gsi.go.jp/xyz/blank/{z}/{x}/{y}.png',
        //     attribution: gsiAttrTemplate,
        //     image: 'gsi_blank.png'
        // }, {
            // 地理院提供の写真地図（インターネット経由で取得）
            id: 'GSI_ORT_NET',
            label: '写真',
            url: '//cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg',
            attribution: gsiAttrTemplate,
            image: 'gsi_ort.png'
        }, {
            // ESRI提供の写真地図
            id: 'ESRI_IM',
            label: '衛星画像',
            url: '//services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
            attribution: esriAttrTemplate,
            image: 'esri_im.png'
        }, {
            // ESRI提供のOpenStreetMap
            id: 'OSM_ST',
            label: 'OSM',
            apiKey: process.env.ARCGIS_API_KEY,
            itemId: '0fb0ac10931043ba81bef4b2d64d7165', //日本語の道路地図
            styleId: 'OSM:Standard',
            attribution: esriAttrTemplate,
            image: 'osm_st.png'
        // },{
        //     // Google Map 通常地図
        //     id: 'GOOGLE',
        //     label: 'Google Map',
        //     image: 'googlemap_normal.png'
        }
    ];
});
