/**
 * esri-leaflet-geocoderのラッパー・モジュール。
 * @module idis/service/GeoService
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Deferred',
    'app/config',
    'idis/model/UserInfo',
    'esri-leaflet-geocoder'
], function (module, declare, lang, Deferred, config, UserInfo, EsriLeafletGeocoder) {
    /* globals google */
    /* jshint nonew:false */

    /**
     * 住所情報オブジェクト
     * @typedef {Object} GeoService~Address
     * @property {string} LongLabel 末尾に国情報の入った住所情報
     * @property {string} Address 住所情報
     */

    /**
     * 住所取得結果オブジェクト
     * @typedef {Object} GeoService~ReverseResult
     * @property {L.LatLng} latlng Leafletの緯度経度
     * @property {GeoService~Address} address 住所情報
     */

    /**
     * 緯度経度と住所の相互変換サービスのラッパー・クラス。
     * @class GeoService
     * @param {Object} kwArgs
     * @param {string} [kwArgs.url] 相互変換サービスのURL
     */
    return declare(null, {
        _parameter: null,
        _arcgisUrl: null,
        _localUrl: null,
        _type: null,
        _retryCnt: 0,
        _dfd: null,

        /**
         * geocode用インスタンス
         * @type {Object}
         * @private
         */
        _coder: null,

        /**
         * reverseGeocode用インスタンス
         * @type {Object}
         * @private
         */
        _reverseCoder: null,

        _err: null,

        constructor: function (kwArgs) {
            // Public ArcGIS 
            this._arcgisUrl = config.geocode.arcgisUrl;
            // Local 
            this._localUrl = config.geocode.localUrl;

            // インスタンス生成時のパラメータにurlが含まれる場合、第1のgisUrlをパラメータのUrlに置き換える。
            this._parameter = kwArgs;
            if ('url' in this._parameter) {
                this._arcgisUrl = this._parameter.url;
            }
        },

        /**
         * ジオコード系サービスを作る
         * ArcGIS->Local->Googleの順で初期化を呼び出されるたびに切り替えていく。
         * Googleの後に初期化を呼び出された場合、エラーとして終了する
         * 作るときにAPI呼び出しが発生するので, constructorでは行わず, 必要なときにやる.
         */
        init: function () {
            this._coder = null;
            this._reverseCoder = null;
            this._parameter.timeout = 5000; // タイムアウトを3秒に設定する
            if (this._retryCnt > 2) {
                this._type = "ERROR";
                return;
            }

            if (this._type === null) {
                this._dfd = new Deferred();
                var idType = UserInfo.getId().slice(-1); // 末尾1文字取得
                if (!isFinite(idType)) {
                    this.initGoogle(); // 末尾が数値でない場合Googleで初期化
                    return;
                } else {
                    if (Number(idType) % 2) {
                        this.initArcGis();
                    } else {
                        this.initGoogle();
                    }
                }
                return;
            }
            if (this._type === "ArcGIS") {
                if (this._retryCnt > 1) {
                    this.initLocal();
                } else {
                    this.initGoogle();
                }
                return;
            }
            if (this._type === "Google") {
                if (this._retryCnt > 1) {
                    this.initLocal();
                } else {
                    this.initArcGis();
                }
                return;
            }
            this._type = "ERROR";
        },

        /**
         * PublicのArcGISのインスタンスを生成し、geoCodeとreverseCodeを設定する
         */
        initArcGis: function () {
            this._type = "ArcGIS";
            this._parameter.url = this._arcgisUrl;
            var service = new EsriLeafletGeocoder.geocodeService(this._parameter);
            // geocode・reverseGeocode用インスタンス
            this._coder = service.geocode();
            this._reverseCoder = service.reverse();
        },

        /**
         * Localのインスタンスを生成し、geoCodeとreverseCodeを設定する
         * localUrlのパラメータが未設定の場合、エラーとして終了する
         */
        initLocal: function () {
            this._type = "Local";
            // URLの指定がない場合は後続処理をスキップします。
            if (!this._localUrl) {
                this._type = "ERROR";
                return;
            }
            this._parameter.url = this._localUrl;
            var service = new EsriLeafletGeocoder.geocodeService(this._parameter);
            // geocode・reverseGeocode用インスタンス
            this._coder = service.geocode();
            this._reverseCoder = service.reverse();
        },

        /**
         * Googleのインスタンスを生成し、geoCodeとreverseCodeを設定する
         * GoogleUrlのパラメータが未設定の場合、エラーとして終了する
         */
        initGoogle: function () {
            this._type = "Google";
            // Googleが死んでいる場合、エラーとして終了
            if (typeof google === 'undefined') {
                return;
            }
            var service = new google.maps.Geocoder();
            // geocode・reverseGeocode用インスタンス
            this._coder = service;
            this._reverseCoder = service;
        },
        /**
         * 指定された文字列から緯度経度を取得して返す。
         * @see {@link http://esri.github.io/esri-leaflet/api-reference/tasks/geocode.html}
         * @function geocode
         * @param {string} text 検索対象文字列
         * @returns {Promise<GeoService~Result[]>} 結果の配列
         */
        geocode: function (text) {
            // エラーとなっている場合、非同期をエラーとして返す。
            if (this._type === "ERROR") {
                // エラーとなった場合、再実行できるようにするためTypeを空にしてリセットする
                this.resetParam();

                // reject error
                return this._dfd.reject(this._err);
            }
            // 初回でgeoCoderが初期化されてない場合、初期化する
            if (!this._coder) {
                this.init();
            }

            // geoCoderのインスタンスが存在しなければ、非同期をエラーとして返す
            if (this._coder === null) {
                this._retryCnt += 1;
                return this.geocode(text);
            }

            if (this._type === "Google") {
                this._coder.geocode({ 'address': text },
                    lang.hitch(this, function (result, status) {
                        this._dfd.progress(result, status);

                        if (status === google.maps.DirectionsStatus.OK) {
                            console.log(result);
                            result.forEach(function (r) {
                                r.latlng = {
                                    lat: r.geometry.location.lat(),
                                    lng: r.geometry.location.lng()
                                };
                            });
                            this.resetParam();
                            return this._dfd.resolve(result);
                        } else {
                            this._err = result;
                            this._retryCnt += 1;
                            this.init();
                            return this.geocode(text);
                        }
                    }));
            } else {
                // 住所から緯度経度を変換する
                this._coder.text(text).run(lang.hitch(this, function (err, res) {
                    if (err) {
                        // エラー内容を保持しておく
                        this._err = err;
                        this._retryCnt += 1;
                        // 次の初期化を実施する
                        this.init();
                        // 次のサービスで、住所から緯度経度を変換する
                        return this.geocode(text);
                    } else {
                        // 成功した場合、成功した情報を非同期で返す
                        this.resetParam();
                        return this._dfd.resolve(res.results);
                    }
                }));
            }
            return this._dfd.promise;
        },

        /**
         * 指定された緯度経度から住所を取得して返す。
         * @see {@link http://esri.github.io/esri-leaflet/api-reference/tasks/reverse-geocode.html}
         * @param {L.LatLng} latlng Leafletの緯度経度
         * @param {number} [distance=10000] 指定座標から半径何メートルまでの住所を探すか
         * @returns {Promise<GeoService~ReverseResult>} 住所取得結果
         */
        reverseGeocode: function (latlng, distance) {
            // エラーとなっている場合、非同期をエラーとして返す。
            if (this._type === "ERROR") {
                // エラーとなった場合、再実行できるようにするためTypeを空にしてリセットする
                this.resetParam();

                // reject error
                return this._dfd.reject(this._err);
            }
            if (!this._reverseCoder) {
                this.init();
            }

            // reverseGeocoderのインスタンスが存在しなければ、非同期をエラーとして返す
            if (this._reverseCoder === null) {
                this._retryCnt += 1;
                return this.reverseGeocode(latlng, distance);
            }


            if (this._type === "Google") {
                this._reverseCoder.geocode({ 'location': latlng },
                    lang.hitch(this, function (result, status) {
                        this._dfd.progress(result, status);

                        if (status === google.maps.DirectionsStatus.OK) {
                            var isValid = false;
                            result.forEach(function (r) {
                                if (r.formatted_address.indexOf("大阪府") > -1) {
                                    isValid = true;
                                }
                                r.address = {
                                    Address: r.formatted_address.substring(r.formatted_address.indexOf("大阪府"))
                                };
                            });
                            if (isValid) {
                                this.resetParam();
                                return this._dfd.resolve(result[0]);
                            } else {
                                // 大阪府が含まれない場合
                                this._err = "住所を取得できませんでした。";
                                this._retryCnt += 1;
                                this.init();
                                return this.reverseGeocode(latlng, distance);
                            }

                        } else {
                            this._err = result;
                            this._retryCnt += 1;
                            this.init();
                            return this.reverseGeocode(latlng, distance);
                        }
                    }));
            } else {
                this._reverseCoder.latlng(latlng).distance(distance || 10000).run(lang.hitch(this, function (err, res) {
                    if (err) {
                        // エラー内容を保持しておく
                        this._err = err;
                        this._retryCnt += 1;
                        // 次の初期化を実施する
                        this.init();
                        // 次のサービスで、住所から緯度経度を変換する
                        return this.reverseGeocode(latlng, distance);
                    } else {
                        // 成功した場合、成功した情報を非同期で返す
                        if ('results' in res) {
                            this.resetParam();
                            return this._dfd.resolve(res.results);
                        }
                        if ('address' in res) {
                            this.resetParam();
                            return this._dfd.resolve(res);
                        }
                    }
                }));
            }
            return this._dfd.promise;
        },
        resetParam: function () {
            this._type = null;
            this._coder = null;
            this._reverseCoder = null;
            this._retryCnt = 0;
        }
    });
});

