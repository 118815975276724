/**
 * 地図上のレイヤーを管理、追加、削除などをするモジュール
 * @module app/control/LayerControl
 */
define([
    'module',
    'dojo',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/aspect',
    'dojo/date/locale',
    'dojo/Deferred',
    'dojo/promise/all',
    'dojox/lang/functional/array',
    'dojo/on',
    'leaflet',
    'idis/model/UserInfo',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/error/InvalidArgumentException',
    'idis/service/Requester',
    // 'app/model/RainfallObservatoryStore',
    'app/model/RiverLevelObservatoryStore',
    // 'app/model/TideLevelObservatoryStore',
    // 'app/model/CrisisManageRiverLevelObsavatoryStore',
    // 'app/model/DamObservatoryStore',
    //  'app/model/BasinObservatoryStore',
    // 'app/model/LocalWeatherObservatoryStore',
    'app/observation/map/ObservationLayer',
    'app/shelter/map/ShelterLayer',
    'app/observation/map/SedimentWarningLayer',
    'app/observation/map/ObservationRasterLayer',
    'app/observation/map/Grib2MeshLayer',
    'app/map/layer/SedimentMeshMapPopup',
    'app/config',
    'dojo/json',
    // 以下、引数で受けないモジュール
    'idis/consts/USER_TYPE',
    'leaflet.markercluster',
    'idis/control/GeoJSONTileLayer'
], function (
    module, dojo, array, declare, lang, aspect, locale, Deferred, all, df, on, L,
    UserInfo, Locator, Router, InvalidArgumentException, Requester,
    // RainfallObservatoryStore, 
    RiverLevelObservatoryStore,
    // TideLevelObservatoryStore,CrisisManageRiverLevelObsavatoryStore, DamObservatoryStore, BasinObservatoryStore, LocalWeatherObservatoryStore,
    ObservationLayer, ShelterLayer, SedimentWarningLayer,
    // ObservationRasterLayer, Grib2MeshLayer, config, USER_TYPE
    ObservationRasterLayer, Grib2MeshLayer, SedimentMeshMapPopup, config, json
) {

    // geojsonのfeature.propertiesのうち、'TanJson' であることを表すkeyの一覧
    var tanJsonKeys = ['_markerType', '_color', '_weight', '_opacity', '_lineStyle',
        '_className', '_stroke', '_fill', '_fillColor', '_fillOpacity',
        '_dashArray', '_lineCap', '_lineJoin', '_clickable',
        '_iconUrl', '_iconSize', '_iconAnchor', '_html', '_radius'];

    /**
     * 指定されたデータがスタイルつきGeoJSONかどうかを判定する。
     * dataのいずれかのfeatureのpropertiesに_markerTypeが含まれていればTanJsonとみなす。
     * @see {@link https://github.com/gsi-cyberjapan/geojson-with-style-spec}
     * @function _isTanJson
     * @param {object} data 判定対象のデータ
     * @returns 対象のデータがスタイルつきGeoJSONならtrue、それ以外の場合はfalse
     * @private
     */
    function _isTanJson(data) {
        if (!data || !lang.isArray(data.features)) {
            return false;
        }
        // いずれかのfeatureが条件を満たすなら正
        return array.some(data.features, function (feature) {
            // featureが存在しなかったらfalse
            if (!feature) {
                return false;
            }
            // feature.propertiesが存在しなかったらfalse
            if (!feature.properties) {
                return false;
            }

            // properties内のkeyをチェック
            return array.some(tanJsonKeys, function (key) {
                if (feature.properties[key]) {
                    return true;
                }
            });
        });
    }

    /**
     * レイヤーのレコードに含まれるURL指定を解析し、
     * 相対パス指定ならばレイヤーのIDに応じたパスに変換する。
     * @param {string} url 解析するURL
     * @param {identifier} id レイヤーの識別子
     * @returns {string} 変換後のURL。urlとして偽値が与えられた場合は偽値そのまま。
     */
    function _parseUrl(url, id) {
        if (!id && id !== 0) {
            throw new InvalidArgumentException(module.id + '::_parseUrl: idの指定は必須です');
        }
        // 偽値か完全なURLの場合はそのまま返す
        if (!url || url.indexOf('://') !== -1 || url.indexOf('/') === 0) {
            return url;
        }
        // IDに応じたデータ用ディレクトリー直下からの相対パスと見なす
        return ['/data/layer/data', id, url].join('/');
    }

    /**
     * 地図上のレイヤー管理
     * @class LayerControl
     */
    return declare(null, /** @lends module:app/control/LayerControl~LayerControl# */ {
        /**
         * 地図オブジェクト
         * @type {Map}
         */
        map: null,

        /**
         * 各レイヤーの識別子とLayerオブジェクトの対応付け
         * @type {Object<identifier,Layer>}
         */
        layers: null,

        /**
         * レイヤーの表示順を管理する配列
         * @type {identifier[]}
         */
        displayOrder: null,

        /**
         * 地図上に描画された市町(被害用)
         */
        drawnDamageIds: [],
        damageMarkerGroupList: [],

        /**
         * 各レイヤーの識別子とLayerオブジェクトの対応付け（被害統合用）
         */
        integrationlayers: {},

        /**
         * 初期化
         * @param  {Map}    map 表示しているMapオブジェクト
         */
        constructor: function (map) {
            this.map = map;
            this.layers = {};
            this.displayOrder = [];
            // ZoomLevelでLayerの表示非表示を切り替える
            this.map.on('zoomend', this.zoomLevelManagement, this);
        },

        remove: function () {
            this.map.off();
            this.inherited(arguments);
        },

        /**
         * 現在追加されているレイヤー一覧を返す。
         * @returns {ILayer} このウィジェットのAPI経由でMapへ追加されたレイヤー一覧
         */
        getLayers: function () {
            return this.layers;
        },

        /**
         * 現在表示されているレイヤーのレイヤーIDを、表示順（後方にあるレイヤーが先）に並び替えてリスト形式で返す。
         * @returns [layerId] このウィジェットのAPI経由でMapへ追加されたレイヤーのレイヤーID
         */
        getLayersByDisplayOrder: function () {
            // 現在表示されいているレイヤIDのリストを取得（表示順とは必ずしも一致しない）
            var layerIdList = Object.keys(this.layers);
            // this.displayOrderは、レイヤーの表示順を上にあるレイヤーから示す配列（現在非表示のレイヤーも含むことがある）
            var layerIdsBottomToTop = this.displayOrder.slice().reverse();

            // 現在表示中のレイヤーだけを、表示順に並び替える
            var orderedLayers = array.filter(layerIdsBottomToTop, function (layerId) {
                return layerIdList.indexOf(String(layerId)) !== -1;
            });

            return orderedLayers;
        },

        /**
         * 指定されたIDのレイヤーを取得する。
         * @param {identifier} id レイヤーの識別子
         * @returns {ILayer} 指定されたIDのレイヤー
         */
        getLayerById: function (id) {
            var layer = this.layers[id];
            if (!layer) {
                console.warn(module.id + '::getLayerById: 指定されたIDのレイヤーが存在しません: id=' + id);
            }
            return layer;
        },

        /**
         * 作成したレイヤーを地図に載せる
         * @param  {ILayer} layer 作成したレイヤーオブジェクト
         */
        addLayer: function (layer, id) {
            if (!this.map.hasLayer(layer)) {
                this.map.addLayer(layer);
                this.layers[id] = layer;
            }
        },

        /**
         * 矢印の頭を地図に載せる
         * @param  {ILayer} layer 作成したレイヤーオブジェクト
         */
        addArrowHead2Map: function (layer) {
            layer.eachLayer(function (feature) {
                if (feature.options && feature.options.drawType === 'arrow') {
                    feature.setArrowHead(feature.options);
                }
            });
        },

        /**
         * 矢印の頭を地図から取り除く
         * @param  {ILayer} layer 作成したレイヤーオブジェクト
         */
        removeArrowHeadFromMap: function (layer) {
            if (layer._layers) {
                layer.eachLayer(lang.hitch(this, function (feature) {
                    if (feature.options && feature.options.drawType === 'arrow') {
                        if (feature.options.bothArrow === true) {
                            for (var i = 0; i <= feature.options.arrowHead.length - 1; i++) {
                                this.map.removeLayer(feature.options.arrowHead[i]);
                            }
                        } else {
                            this.map.removeLayer(feature.options.arrowHead);
                        }
                    }
                }));
            }
        },

        /**
         * 作成したレイヤーをID付きで地図に載せる
         * IDリストはこのモジュールの中で管理され、表示切り替えに用いる
         * @param  {ILayer}    layer 作成したレイヤーオブジェクト
         * @param  {String}    id    作成したレイヤーを識別するためのID文字列
         */
        addLayer4Id: function (layer, id) {
            if (!this.map.hasLayer(layer)) {
                this.layers[id] = layer;
            }
        },

        /**
         * 指定されたレイヤーを地図から削除する
         * @param  {ILayer}    layer 削除したいレイヤーオブジェクト
         */
        removeLayer: function (layer) {
            if (this.map.hasLayer(layer)) {
                this.map.removeLayer(layer);
                this.removeArrowHeadFromMap(layer);
            }
        },

        /**
         * IDにより指定されたレイヤーを地図から削除する
         * @param {string} id 削除したいレイヤーを識別するためのID文字列
         */
        removeLayerById: function (id) {
            // 引数チェック
            if (!id && id !== 0) {
                throw new InvalidArgumentException(module.id + '#removeLayerById: 不正なidです: "' + id + '"');
            }
            var layer = this.layers[id];

            // 削除対象のレイヤーがなかったら何もしない
            if (!layer) {
                console.warn(module.id + '#removeLayerById: レイヤーが見つかりません: id=' + id);
                return;
            }

            this.removeLayer(layer);
            delete this.layers[id];
            // 被害レイヤを削除
            if (layer.options.infoCategoryCd === 'D100') {
                this.removeDamageCircles(id);
            }

            // 被害統合用に管理しているレイヤを削除
            var integrationlayer = this.integrationlayers[id];
            if (!integrationlayer) {
                return;
            }
            delete this.integrationlayers[id];

        },

        /**
         * 地図上の被害レイヤを削除する
         */
        removeDamageCircles: function (id) {
            array.forEach(this.damageMarkerGroupList, function (group) {

                var markerLayerList = group.markerGroup.getLayers();
                var oldMarkerLayer = array.filter(markerLayerList, function (layer) {
                    return String(layer.layerId) === id;
                });
                array.forEach(oldMarkerLayer, function (removeLayer) {
                    // 古いレイヤーを削除
                    group.markerGroup.removeLayer(removeLayer);
                });
                group.ids = array.map(group.markerGroup.getLayers(), function (layer) { return layer.layerId; });
            });
            // レイヤのない市町村グループを削除
            this.damageMarkerGroupList = array.filter(this.damageMarkerGroupList, function (group) {
                if (group.ids.length > 0) {
                    return true;
                } else {
                    this.map.removeLayer(group.markerGroup);
                }
            }, this);
            // 被害報告IDリストをリセット
            this.drawnDamageIds.length = 0;
        },

        /**
         * 処理名：汎用レイヤー追加。
         * 処理概要：タイプに応じたレイヤーを追加する。
         *         ツリーをチェックした時にこのfunctionを呼び出す
         *
         * @param {Object} layerInfo 表示レイヤー情報
         * @param {identifier} layerInfo.id 表示レイヤーの識別子
         * @param {number} opacity 透過度
         * @return なし
         */
        addGeneralLayer: function (layerInfo, opacity) {
            // 登録済みの場合は最前面へ移動して終了
            if (this.map.hasLayer(this.layers[layerInfo.id])) {
                this.toFront(layerInfo.id);
                return new Deferred().resolve();
            }

            // infoCategoryCodeで出し分ける場合
            var resultOfInfoCateogoryCode = this.addLayerByInfoCategoryCode(layerInfo, opacity);
            if (resultOfInfoCateogoryCode) {
                return resultOfInfoCateogoryCode;
            }

            // jsonTypeで出し分ける場合
            // JSON種別に対応するレイヤー追加関数名を取得
            var methodNameForJsonType = {
                1: 'addGeoJsonLayer',
                2: 'addTiledGeoJsonLayer',
                3: 'addTiledRasterLayer',
                4: 'addDynamicDeliveryVectorLayer',
                5: 'addDynamicDeliveryImageLayer',
                6: 'addLayerWithStatus',
                7: 'addObservationLayer',
                8: 'addMunicipalityLayer',
                9: 'addShelterLayer',
                A: 'addSedimentWarningLayer',
                B: 'addRoadNaviLayer',
                C: 'addObservationRasterLayer',
                D: 'addDamageReportLayer',
                E: 'addTrafficLayer',
                F: 'addRoadInfoGeoJsonLayer',
                G: 'addWmsTiledRasterLayer',
                H: 'addTyphoonLayer'
            }[layerInfo.jsonType];

            // FIXME: Loaderになったまま固まってしまう問題のPDのためログを仕込む。解決したら要削除。
            console.log('layerInfo:');
            console.log(layerInfo);
            console.log('methodNameForJsonType:' + methodNameForJsonType);

            // 行政界は無視
            if (!methodNameForJsonType && layerInfo.id !== '999') {
                console.log('★★★ ERROR ★★★');
                console.log('layerInfo:');
                console.log(layerInfo);
                return;
            }
            // レイヤー追加関数を実行して結果を返す
            return this[methodNameForJsonType].call(this, layerInfo, opacity);
        },

        /**
         * InfoCategoryCodeによるレイヤー生成
         * @param {Object} layerInfo 表示レイヤー情報
         * @param {number} opacity 透過度
         */
        addLayerByInfoCategoryCode: function (layerInfo, opacity) {
            var layer = null;
            switch (layerInfo.infoCategoryCd) {
                case 'O007': // 土砂災害危険度情報(メッシュ)
                    console.log('layerInfo.id:' + layerInfo.id);
                    layer = new SedimentMeshMapPopup(
                        _parseUrl(layerInfo.layerUrl, layerInfo.id),
                        layerInfo,
                        opacity
                    );
                    if (opacity === 1) {
                        opacity = 0.7;
                    }
                    break;
                case 'O009': // 土壌雨量指数(実況)
                case 'O010': // 土壌雨量指数(予想)
                case 'O013': // 大雨警報危険度分布
                case 'O014': // 高解像度降水ナウキャスト
                case 'O015': // 洪水警報危険度分布
                case 'O016': // 解析積雪深・解析降雪量
                    layer = new Grib2MeshLayer(layerInfo);
                    layer.options.pane = 'shadowPane';
                    // デフォルト(1)だったら透過度を入れる
                    // 地図画面の透過度スライダーは合わせて変更されないので注意
                    if (opacity === 1) {
                        opacity = 0.7;
                    }
                    break;

                /* case 'O015': // 洪水警報危険度分布
                    layer = new TimeSeriesGeoJsonLayer(layerInfo);
                    opacity = 1.0;
                    break; */

                default:
                    return null;
            }

            if (layer) {
                this.addLayerWithInfoCategory(layer, layerInfo, opacity);
            }

            return new Deferred().resolve();
        },

        /**
         * InfoCategoryCdによるレイヤー生成時の地図表示
         *
         * 透過度とInfoCategoryCdをレイヤー情報に入れておく
         *
         * @param {*} layer 生成したレイヤーオブジェクト, これを地図にaddする
         * @param {*} layerInfo レイヤー情報(tlayer)
         * @param {*} opacity 透過度
         */
        addLayerWithInfoCategory: function (layer, layerInfo, opacity) {
            var id = layerInfo.id;

            this.addLayer(layer, id);

            // 透過度の設定
            if (this.layers[id].options) {
                this.layers[id].options.opacity = opacity;
                this.layers[id].defaultOpacity = opacity;
                this.setDefaultFillOpacity(this.layers[id], opacity);

                // 情報種別の設定
                this.layers[id].options.infoCategoryCd = layerInfo.infoCategoryCd;
            }

            // 前面表示
            this.toFront(id);
        },

        /**
         * 指定されたURLにあるstyle.jsを読み込み、結果をPromiseとして返す。
         * @param {string} url
         * @returns {Promise<Object>} スタイルオブジェクトを返すPromise
         */
        _getStyle: function (url) {
            // 引数チェック
            if (!url) {
                throw new InvalidArgumentException(module.id + '#_getStyle: urlが不正です: url="' + url + '"');
            }
            // 一旦テキスト形式で取得してから`eval`する
            return Requester.get(url, {
                handleAs: 'text'
            }).then(lang.hitch(this, function (data) {
                if (!data) {
                    throw new Error(module.id + '#_getStyle: style.jsの書式が不正です: url=' + url);
                }
                return eval('(' + data + ')'); //jshint ignore: line
            }));
        },

        /**
         * 処理名：道路ナビレイヤー追加
         * 処理概要：広島県道路ナビのAPIから取得する情報を元にレイヤーを追加する。
         *
         * @param {Object} layerInfo レイヤー情報
         * @param {identifier} layerInfo.id レイヤーの識別子
         * @param {string} layerInfo.layerUrl レイヤーの各タイルの配置を決めるURL
         * @param {string} [layerInfo.styleUrl] レイヤーのstyle.jsが設置されているURL
         * @param {number} opacity 透過度
         * @returns {Promise} 追加処理が終わったら解決するPromise
         */
        addRoadNaviLayer: function (layerInfo, opacity) {
            var id = layerInfo.id;

            // 道路ナビでは２通りの緯度経度情報の持ち方をしている。
            // 情報種別と緯度経度情報をマッピングする。
            var patterns = {
                'R001': 'kukanroot',
                'R002': 'kukanroot',
                'R003': 'kukanroot',
                'R004': 'lonlat',
                'R005': 'lonlat',
                'R006': 'kukanroot'
            };

            // レイヤ表示に必要な情報を取得する。
            all({
                res: Requester.get(_parseUrl(layerInfo.layerUrl, id)),
                style: layerInfo.styleUrl && this._getStyle(_parseUrl(layerInfo.styleUrl, id)),
                pattern: patterns[layerInfo.infoCategoryCd]
            }).then(lang.hitch(this, function (result) {
                // styleオブジェクトに状況データを埋め込む
                if (result.res.results) {
                    result.style.geojsonOptions.results = result.res.results;
                }

                // そのままだと style()などで 'results' が見えないので、bindしてthisをこのオブジェクトに向かせる
                if (result.style.geojsonOptions.style) {
                    result.style.geojsonOptions.style =
                        result.style.geojsonOptions.style.bind(result.style.geojsonOptions);
                }

                if (result.style.geojsonOptions.filter) {
                    result.style.geojsonOptions.filter =
                        result.style.geojsonOptions.filter.bind(result.style.geojsonOptions);
                }

                if (result.style.geojsonOptions.pointToLayer) {
                    result.style.geojsonOptions.pointToLayer =
                        result.style.geojsonOptions.pointToLayer.bind(result.style.geojsonOptions);
                }

                var options = result.style && result.style.geojsonOptions;

                // 情報カテゴリ種別毎に異なる形式で座標を保持しているので、geojsonを作る方法を呼び分ける。
                var layer;
                switch (result.pattern) {
                    case 'kukanroot':
                        layer = L.geoJson(this._generateRoadRes2GeoJson(result.res), options);
                        break;
                    case 'lonlat':
                        layer = L.geoJson(this._generateLonLat2GeoJson(result.res), options);
                        break;
                    default:
                        return;
                }

                this.layers[id] = layer.addTo(this.map);
                // 透過度の設定
                this.layers[id].options.opacity = opacity;
                this.setDefaultFillOpacity(this.layers[id]);

                // 情報種別の設定
                this.layers[id].options.infoCategoryCd = layerInfo.infoCategoryCd;

                // 前面表示
                this.toFront(id);
            }));
        },

        /**
         * 道路ナビのレスポンスからGeoJsonを作成する。
         * @param  {[type]} res 道路ナビAPIのレスポンス
         * @return {[type]} GeoJson
         */
        _generateRoadRes2GeoJson: function (res) {
            var geojson = {
                type: 'FeatureCollection',
                features: []
            };

            res.results.forEach(function (result, i) {
                // PointのFeatureを取得して、GeoJsonに追加する。
                var pointFeature = this._roadRes2Feature('Point', [result.lon, result.lat], result, i + 1);
                geojson.features.push(pointFeature);

                // 規制区間の(Multi)LineStringを保持していなければスキップする。
                // [20180723現在] null, POINT EMPTY, POINT EMPTY@@POINT EMPTYの３パターンを確認。
                if (!result.kukanroot || !result.kukanroot.indexOf('POINT EMPTY')) {
                    console.log('skipped:' + result.kukanroot);
                    return;
                }

                // 規制区間はLineStringとMultiLineStringの場合がある.
                // いずれかを保持していればGeoJsonに追加する。
                var type = '';
                var coordinates = [];
                switch (result.kukanroot.match(/MULTILINESTRING|LINESTRING/)[0]) {
                    case 'LINESTRING':
                        type = 'LineString';
                        // レスポンスの文字列は LINESTRING (xxxxx yyyyy, xxxxx yyyyyy)の形式となっているので、
                        // GeoJsonとして扱い易い緯度経度形式に持ち変える。
                        var linelatlng = result.kukanroot.match(/\((.+)\)/)[1].split(', ');
                        linelatlng.forEach(function (latlng) {
                            var array = latlng.split(' ');
                            coordinates.push(array);
                        }, this);
                        break;
                    case 'MULTILINESTRING':
                        type = 'MultiLineString';
                        // レスポンスの文字列は MULTILINESTRING ((xxxxx yyyyy, xxxxx yyyyyy),(xxxxx yyyyy, xxxxx yyyyyy))の
                        // 形式となっているので、GeoJsonとして扱い易い緯度経度形式に持ち変える。
                        var multilinestr = result.kukanroot.match(/MULTILINESTRING \((.+)\)$/)[1];
                        var multilinearrays = multilinestr.match(/\((.+?)\)/g);
                        multilinearrays.forEach(function (multilinearray) {
                            var multilinelatlng = multilinearray.match(/\((.+)\)/)[1].split(', ');
                            var coordinate = [];
                            multilinelatlng.forEach(function (latlng) {
                                var array = latlng.split(' ');
                                coordinate.push(array);
                            }, this);
                            coordinates.push(coordinate);
                        });
                        break;
                    // その他の場合はスキップする。
                    default:
                        console.log(result.kukanroot);
                        return;
                }
                // (Multi)LineStringのfeatureを取得してGeoJsonに追加する。
                var polylineFeature = this._roadRes2Feature(type, coordinates, result, i + 1);
                geojson.features.push(polylineFeature);
            }, this);

            return geojson;
        },

        /**
         * 道路ナビのレスポンスからGeoJsonのfeatureを作成するローカルメソッド。
         * @param  {[type]} type featureの形式
         * @param  {[type]} coordinates featureの座標
         * @param  {[type]} result respons.resultsに登録されている中の１つの情報
         * @param  {[type]} id このfeatureにつけるid
         * @return {[type]} 作成したfeature
         */
        _roadRes2Feature: function (type, coordinates, result, id) {
            // featureの雛形
            var feature = {
                geometry: {
                    type: type,
                    'coordinates': coordinates
                },
                type: 'Feature',
                id: id,
                properties: {
                }
            };
            // すべてのプロパティをfeature.propertiesに入れてしまう
            for (var key in result) {
                if (result.hasOwnProperty(key)) {
                    feature.properties[key] = result[key];
                }
            }
            return feature;
        },

        /**
         * 道路ナビのレスポンスLonlatからGeoJsonを作成するメソッド。
         * @param  {[type]} res featureの形式
         * @return {[type]} GeoJson
         */
        _generateLonLat2GeoJson: function (res) {
            var geojson = {
                type: 'FeatureCollection',
                features: []
            };
            res.forEach(function (layer) {
                if (!layer.LonLat) { return; }
                layer.LonLat.forEach(function (multiLonlat, i) {
                    var coordinates = [];
                    multiLonlat.forEach(function (lonlat) {
                        coordinates.push([lonlat.e, lonlat.d]);
                    });
                    var feature = {
                        geometry: {
                            type: 'LineString',
                            'coordinates': coordinates
                        },
                        type: 'Feature',
                        id: i + 1,
                        properties: {
                            name: layer.name,
                            color: layer.color,
                            type: layer.type,
                            weight: layer.weight
                        }
                    };
                    geojson.features.push(feature);
                }, this);
            });
            return geojson;
        },

        /**
         * 処理名：GeoJSON・タンJSONレイヤー追加。
         * 処理概要：GeoJSONファイルからレイヤーを追加する。
         *
         * @param {Object} layerInfo レイヤー情報
         * @param {identifier} layerInfo.id レイヤーの識別子
         * @param {string} layerInfo.layerUrl レイヤーの各タイルの配置を決めるURL
         * @param {string} [layerInfo.styleUrl] レイヤーのstyle.jsが設置されているURL
         * @param {number} opacity 透過度
         * @returns {Promise} 追加処理が終わったら解決するPromise
         */
        addGeoJsonLayer: function (layerInfo, opacity) {
            var id = layerInfo.id;

            // Spectee全情報の場合は、SpecteeAPIよりGeoJSONを取得するURLを編集
            if (layerInfo.infoCategoryCd === 'D117' && layerInfo.parentId === 7) {
                layerInfo.layerUrl = this.editUrl4SpecteeLayer(layerInfo);
            }

            return all({
                // layerUrlに指定されたファイルをIDに対応するディレクトリーから取得
                data: Requester.get(_parseUrl(layerInfo.layerUrl, id)),
                // layerInfoがstyleを持っている場合は取得
                style: layerInfo.styleUrl && this._getStyle(_parseUrl(layerInfo.styleUrl, id))
            }).then(lang.hitch(this, function (result) {
                // スタイルつきGeoJSONならTanJSONとして、それ以外なら通常のGeoJSONとしてレイヤー化
                var options = result.style && result.style.geojsonOptions;
                // geojsonのfeature.propertiesのうち、'TanJson' であることを表すkeyがあるが、TanJsonとして扱わないレイヤー一覧
                var noTanJsonInfoList = ['S003','S004','S005'];
                // var layer = (_isTanJson(result.data) ? L.tanJson : L.geoJson)(result.data, options);
                var layer = ((_isTanJson(result.data) && noTanJsonInfoList.indexOf(layerInfo.infoCategoryCd) === -1) ?
                    L.tanJson : L.geoJson)(result.data, options);

                this.layers[id] = layer.addTo(this.map);
                this.addArrowHead2Map(layer);
                if (this.checkZoomSwitchTarget(layerInfo, layer)) {
                    this.map.removeLayer(layer);
                    this.removeArrowHeadFromMap(layer);
                }
                // 透過度の設定
                this.layers[id].options.opacity = opacity;
                this.setDefaultFillOpacity(layer);
                this.setLayerOpacity(layer, opacity);

                // 情報種別の設定
                this.layers[id].options.infoCategoryCd = layerInfo.infoCategoryCd;
                // 前面表示
                this.toFront(id);
            }), function (err) {
                console.error(err);
            });
        },

        /**
         * Spectee全情報JSON取得URL編集
         * Specteeの全情報（東京都の直近24時間分）のJSONを取得するURLを編集する。
         *
         * @param {Object} layerInfo レイヤー情報
         * @returns {string} [layerInfo.layerUrl] Spectee全情報JSON取得URL
         */
        editUrl4SpecteeLayer: function (layerInfo) {
            // サーバー側の処理（Spectee情報を取得し、レイヤー用JSONに成型して返却）を呼ぶURLを編集
            var now = new Date();

            // 現在の日時を取得し、検索対象期間(終了)にセットする
            var beforeDate = now.getTime();
            beforeDate = locale.format(new Date(beforeDate), {
                selector: 'date',
                datePattern: 'yyyy-MM-dd HH:mm:00'
            });
            // 現在より1日前の日時を取得し、検索対象期間(開始)にセットする
            now.setDate(now.getDate() - 1);
            var afterDate = now.getTime();
            afterDate = locale.format(new Date(afterDate), {
                selector: 'date',
                datePattern: 'yyyy-MM-dd HH:mm:00'
            });
            // layerInfo.layerUrl = '/api/spectee/allinfo/layer/?prefIds=' + config.municInfo.prePrefCd;
            layerInfo.layerUrl = '/api/spectee/allinfo/layer/?cityIds=';
            layerInfo.layerUrl += '27100,27127,27102,27103,27104,27128,27106,27107,27108,27109,27111,27113,27123,';
            layerInfo.layerUrl += '27114,27115,27116,27117,27118,27124,27119,27125,27120,27121,27126,27122';
            layerInfo.layerUrl += '&afterDate=' + afterDate + '&beforeDate=' + beforeDate;
            layerInfo.layerUrl += '&latest=0';
            return layerInfo.layerUrl;
        },

        /**
         * タイル化JSONレイヤー追加
         * タイル化されたJSON URLからレイヤーを追加する。
         *
         * @param {Object} layerInfo レイヤー情報
         * @param {identifier} layerInfo.id レイヤーの識別子
         * @param {string} [layerInfo.layerUrl] レイヤーの各タイルの配置を決めるURL、未指定時は識別子から算出
         * @param {string} [layerInfo.styleUrl] レイヤーのstyle.jsが設置されているURL、未指定時は識別子から算出
         * @param {number} opacity レイヤーの透過度
         * @returns {Promise} 追加処理が終わったら解決するPromise
         */
        addTiledGeoJsonLayer: function (layerInfo, opacity) {
            var id = layerInfo.id;
            // style.jsのURLを取得
            var styleUrl = _parseUrl(layerInfo.styleUrl || 'style.js', id);
            return this._getStyle(styleUrl).then(lang.hitch(this, function (style) {
                // レイヤー追加
                var layerUrl = _parseUrl(layerInfo.layerUrl || '{z}/{x}/{y}.geojson', id);
                var layer = new L.GeoJSONTileLayer(layerUrl, style.options, style.geojsonOptions);
                layer.options.opacity = opacity;
                this.setDefaultFillOpacity(layer);

                // 情報種別の設定
                layer.options.infoCategoryCd = layerInfo.infoCategoryCd;
                this.addLayer(layer, id);
                // 前面表示
                this.toFront(id);
            }));
        },

        /**
         * 処理名：画像タイルレイヤー追加。
         * 処理概要：タイルURLからレイヤーを追加する。
         *
         * @param {Object} layerInfo レイヤー情報
         * @param {identifier} layerInfo.id レイヤーの識別子
         * @param {string} layerInfo.layerUrl 各タイルのURLを表すテンプレート文字列
         * @param {number} opacity 透過度
         * @returns {Promise} 追加処理が終わったら解決するPromise
         */
        addTiledRasterLayer: function (layerInfo, opacity) {
            var id = layerInfo.id;
            // 適切なURLに変換
            var layerUrl = _parseUrl(layerInfo.layerUrl, id);

            // TileLayerの場合、透過率を20%に設定する
            opacity = 0.8;

            // オプションに透過度、オーバーズーミング設定を加える
            var options = lang.mixin(null, layerInfo, { opacity: opacity });
            options.maxNativeZoom = layerInfo.overlayZoom;

            this.layers[id] = new L.TileLayer(layerUrl, options);
            // レイヤー追加
            this.layers[id].addTo(this.map);
            // 前面表示
            this.toFront(id);
            return new Deferred().resolve();
        },

        /**
         * WMS地図情報レイヤー追加。
         *
         * @param {Object} layerInfo レイヤー情報
         * @param {number} opacity 透過度
         * @returns {Promise} 追加処理が終わったら解決するPromise
         */
        addWmsTiledRasterLayer: function (layerInfo, opacity) {
            var id = layerInfo.id;
            var sip4dLayerDefine = json.parse(layerInfo.wmsLayers);
            // 適切なURLに変換
            // var layerUrl = _parseUrl(layerInfo.layerUrl, id);

            this.layers[id] = new L.tileLayer.wms(
                layerInfo.layerUrl,
                lang.mixin(sip4dLayerDefine, {
                    crs: L.CRS['EPSG' + layerInfo.epsgCd] || L.CRS.Simple,
                    format: 'image/png',
                    transparent: true
                })
            );

            // 透過度設定
            this.layers[id].setOpacity(opacity === 1 ? 0.7 : opacity);
            // レイヤー追加
            this.layers[id].addTo(this.map);
            // 前面表示
            this.toFront(id);
            return new Deferred().resolve();

        },

        /**
         * 処理名：台風解析・予報情報レイヤー追加
         * 処理概要:API経由で取得したデータからレイヤーを追加する
         * @param {Object} layerInfo レイヤー情報
         * @param {identifier} layerInfo.id レイヤーの識別子
         * @param {string} layerInfo.layerUrl レイヤーの各タイルの配置を決めるURL
         * @param {string} [layerInfo.styleUrl] レイヤーのstyle.jsが設置されているURL
         * @param {number} opacity 透過度
         * @returns {Promise} 追加処理が終わったら解決するPromise
         */
        addTyphoonLayer: function (layerInfo, opacity) {
            var id = layerInfo.id;
            return all({
                // layerUrlに指定されたファイルをIDに対応するディレクトリーから取得
                data: Requester.get('/data/typhoon/' + layerInfo.layerUrl),
                // layerInfoがstyleを持っている場合は取得
                style: this._getStyle('/data/typhoon/style.js')
            }).then(lang.hitch(this, function (result) {
                console.log('addTyphoonLayer then');


                // スタイルつきGeoJSONならTanJSONとして、それ以外なら通常のGeoJSONとしてレイヤー化
                var options = result.style && result.style.geojsonOptions;

                var layer = L.layerGroup();
                layer.options = options;
                //var layerPiece = null;
                var linePoint = [];

                // 台風の予想進路用の線を赤色の破線で描画
                var shapeOption = {
                    color: '#EF1616',
                    weight: 3,
                    dashArray: [10, 10]
                };

                array.forEach(result.data.features, function (feature) {
                    var isTanJson = false;
                    // style.jsを元にLayerを生成する
                    array.some(tanJsonKeys, function (key) {
                        if (feature.properties[key]) {
                            isTanJson = true;
                        }
                    });
                    if (isTanJson) {
                        layer.addLayer(
                            L.tanJson(feature, options)
                        );
                    } else {
                        layer.addLayer(
                            L.geoJson(feature, options)
                        );
                    }
                    // 緯度経度を合わせる
                    var point = [feature.geometry.coordinates[1], feature.geometry.coordinates[0]];
                    // 軌跡のために、台風の点を追加する
                    linePoint.push(point);
                    var isActual = feature.properties.forecast === '実況';
                    // 台風のサークルを追加する
                    // 強風域の半径で描画する。
                    var windLayer;
                    if (isActual) {
                        windLayer = L.circle(
                            point,
                            feature.properties.strongWindKm * 1000,
                            { // 実況なら黄色、予測なら破線で描画する
                                color: 'transparent',
                                fillColor: '#FFF100',
                                fillOpacity: 0.5
                            }
                        );
                        layer.addLayer(windLayer);
                    }/*  else {
                        windLayer = L.circle(
                            point,
                            feature.properties.strongWindKm * 1000,
                            {
                                color: '#161616',
                                weight: 3,
                                dashArray: [10, 10],
                                fillColor: 'transparent'
                            }
                        );
                    }
                    layer.addLayer(windLayer); */

                    // 暴風域の半径で描画する
                    var stormLayer;
                    if (isActual) {
                        console.log(feature.properties);
                        stormLayer = L.circle(
                            point,
                            feature.properties.stormKm * 1000,
                            { // 実況なら赤色、予測なら破線で描画する
                                color: 'transparent',
                                fillColor: '#E60012',
                                fillOpacity: 0.5
                            });
                            layer.addLayer(stormLayer);
                    }/*  else {
                        stormLayer = L.circle(
                            point,
                            feature.properties.stormKm * 1000,
                            {
                                color: '#161616',
                                weight: 3,
                                dashArray: [10, 10],
                                fillColor: 'transparent'
                            });
                    }
                    layer.addLayer(stormLayer); */

                    // 実況のデータではない場合、予報円の半径で描画する
                    var probabilityCircleLayer;
                    if (!isActual) {
                        console.log(feature.properties);
                        probabilityCircleLayer = L.circle(
                            point,
                            feature.properties.probabilityCircleRadiusKm * 1000,
                            { // 灰色の破線で描画する
                                color: '#747474',
                                weight: 3,
                                dashArray: [10, 10],
                                fillColor: 'transparent'
                            }
                        );
                        layer.addLayer(probabilityCircleLayer);
                    }

                });
                // 台風の軌跡を追加する
                layer.addLayer(L.polyline(linePoint, shapeOption));

                this.layers[id] = layer.addTo(this.map);

                this.addArrowHead2Map(layer);
                if (this.checkZoomSwitchTarget(layerInfo, layer)) {
                    this.map.removeLayer(layer);
                    this.removeArrowHeadFromMap(layer);
                }
                // 透過度の設定
                this.layers[id].options.opacity = opacity;
                this.setDefaultFillOpacity(layer);
                this.setLayerOpacity(layer, opacity);

                // 情報種別の設定
                this.layers[id].options.infoCategoryCd = layerInfo.infoCategoryCd;

                // 描画中に表示対象レイヤーが変更された場合の対応
                // URLのレイヤーIDを再確認して存在しなければチェックを外す
                // ただし災害ポータルの台風表示ではURLにレイヤーIDがないので本チェックは実施しない
                if (!this._isDisasterPortal()) {
                    var layerFlg = df.keys(Locator.getLayerQuery()).indexOf('' + id) !== -1;
                    if (!layerFlg) {
                        this.removeLayerById(id);
                    }
                }
                // レイヤーの最小限ズームレベルを3まで上げる
                this.map.options.minZoom = 3;
                // 全体がいい感じに収まるようにする
                // →表示が台風の位置に移動しないようコメントアウト
                // this.map.fitBounds(linePoint);
                // ズームレベルで台風が全体的に表示できるよう調整
                if (this._isDisasterPortal()) {
                    // 災害ポータル
                    this.map.setZoom(4);
                }
                // 前面表示
                this.toFront(id);
            }), function (err) {
                console.error(err);
            });
        },

        // 台風表示が災害ポータルであるかを判断する
        // 災害ページは、メニューで選択された場合(p=disasterPortal)と、ログイン直後(p=が存在しない)の場合がある
        _isDisasterPortal: function () {
            if (Router.getCurrentPath() && Router.getCurrentPath() !== 'disasterPortal') {
                return false;
            } else {
                return true;
            }
        },

        /**
         * 処理名：動的配信ベクトルレイヤー追加。
         * 処理概要：URLから動的配信のベクトルレイヤーを追加する。
         *
         * @param {Object} layerInfo レイヤー情報
         * @param {identifier} layerInfo.id レイヤーの識別子
         * @param {number} opacity 透過度
         * @returns {Promise} 追加処理が終わったら解決するPromise
         */
        addDynamicDeliveryVectorLayer: function (layerInfo, opacity) {
            return this._getStyle(layerInfo.lStyleUrl).then(lang.hitch(this, function (style) {
                // 表示中のズームレベルが定義されたズームレベル範囲外の場合はレイヤーを作らない
                var zoomLevel = this.map.getZoom();
                if (zoomLevel > layerInfo.maxZoom || zoomLevel < layerInfo.minZoom) {
                    return;
                }

                // レイヤー追加
                var id = layerInfo.id;
                var layerUrl = _parseUrl(layerInfo.layerUrl, id);
                this.layers[id] = L.esri.featureLayer(layerUrl, style.geojsonOptions);
                this.layers[id].options.opacity = opacity;
                // 情報種別の設定
                this.layers[id].options.infoCategoryCd = layerInfo.infoCategoryCd;
                this.map.addLayer(this.layers[id]);
                this.layers[id].on('load', lang.hitch(this, function () {
                    if (this.layers[id].options.opacity !== 1) {
                        for (var prop in this.layers[id]._layers) {
                            if (!this.layers[id]._layers.hasOwnProperty(prop)) {
                                continue;
                            }
                            this.setDefaultFillOpacity(this.layers[id]._layers[prop]);
                            this.setLayerOpacity(this.layers[id]._layers[prop], this.layers[id].options.opacity);
                        }
                    }
                }));

                // 前面表示
                this.toFront(id);
            }));
        },

        /**
         * 処理名：動的配信画像レイヤー追加。
         * 処理概要：URLから動的配信の画像レイヤーを追加する。
         *
         * @param {Object} layerInfo レイヤー情報
         * @param {identifier} layerInfo.id レイヤーの識別子
         * @param {number} opacity 透過度
         * @returns {Promise} 追加処理が終わったら解決するPromise
         */
        addDynamicDeliveryImageLayer: function (layerInfo, opacity) {
            // レイヤー追加
            var id = layerInfo.id;
            var layerUrl = _parseUrl(layerInfo.layerUrl, id);
            this.layers[id] = L.esri.Layers.dynamicMapLayer(layerUrl, { opacity: opacity });
            this.map.addLayer(this.layers[id]);
            // 前面表示
            this.toFront(id);
            return new Deferred().resolve();
        },

        /**
         * 処理名：状況データを付与したレイヤー追加。
         * 処理概要：事前に決められGeoJSONとlayerUrlで示された状況データを組み合わせたレイヤーを追加する
         *          Lアラートや避難所などのレイヤーで利用される
         *          ここから1つのGeoJSONによるレイヤーとタイル化GeoJSONによるレイヤーに分岐していく
         *
         * @param {Object} layerInfo レイヤー情報
         * @param {number} opacity 透過度
         * @returns {Promise} 追加処理が終わったら解決するPromise
         */
        addLayerWithStatus: function (layerInfo, opacity) {
            switch (layerInfo.infoCategoryCd) {
                case 'D102': // 気象
                    return this.addGeoJSONLayerWithStatus(layerInfo, opacity,
                        '/data/layer/data/lalert/area/district.geojson');
                case 'D004': // 地震
                    return this.addGeoJSONLayerWithStatus(layerInfo, opacity,
                        '/data/layer/data/lalert/earthquake/earthquake-points.geojson', this.addHypocenter);
                case 'D006': // 津波
                    return this.addGeoJSONLayerWithStatus(layerInfo, opacity,
                        '/data/layer/data/lalert/tsunami/coast.geojson');
                case 'D007': // 津波情報
                    return this.addJSONLayerWithStatus(layerInfo, opacity);
                case 'D104': // 火山
                    return this.addGeoJSONLayerWithStatus(layerInfo, opacity,
                        '/data/layer/data/lalert/area/data.geojson', this.addMountainPoint);
                case 'D105': // 土砂
                    return this.addGeoJSONLayerWithStatus(layerInfo, opacity,
                        '/data/layer/data/lalert/area/data.geojson');
                case 'D106': // 竜巻
                    return this.addGeoJSONLayerWithStatus(layerInfo, opacity,
                        '/data/layer/data/lalert/area/data.geojson');
                case 'D107': // 記録的短時間大雨
                    return this.addGeoJSONLayerWithStatus(layerInfo, opacity,
                        '/data/layer/data/lalert/area/data.geojson');
                case 'D103': // 河川
                    return this.addMultipulGeoJSONLayerWithStatus(layerInfo, opacity,
                        {
                            area: '/data/layer/data/lalert/area/data.geojson',
                            river: '/data/layer/data/lalert/river/data.geojson'
                        },
                        this.addRiverObservationPoint
                    );
                case 'D114': // 避難情報
                    // 市町村コードを取得
                    var mcd = Locator.getQueryFrom(layerInfo.layerUrl).municipalityCd;
                    // 地区ごとの境界情報を格納した市町村単位のGeoJSONのURL
                    var borderUrl;
                    if (mcd) {
                        // 地区ごとの境界情報を格納した市町村単位のGeoJSONのURL
                        borderUrl = '/data/layer/data/evacorder/area/' + mcd + '.geojson';
                    } else {
                        // 地区指定が無い場合は外部向けを表示
                        borderUrl = '/data/layer/data/lalert/area/municipality.geojson';
                    }
                    return this.addGeoJSONLayerWithStatus(layerInfo, opacity, borderUrl);
                case 'D115': // 避難所開設情報
                    return this.addGeoJSONLayerWithStatus(layerInfo, opacity,
                        '/data/layer/data/shelter/shelters.geojson');
                case 'M009': // 長周期地震動（震央地別）
                    return this.addGeoJSONLayerWithStatus(layerInfo, opacity,
                            '/data/layer/data/lalert/area/district.geojson');
                case 'M010': // 長周期地震動（観測点別）
                    return this.addGeoJSONLayerWithStatus(layerInfo, opacity,
                            '/data/layer/data/lalert/earthquake/earthquake-points.geojson',
                            this.addHypocenter);
                case 'D121': // 本部設置情報
                    // 市町村コードを取得
                    return this.addGeoJSONLayerWithStatus(layerInfo, opacity,
                        '/data/master/municipalities.geojson', this.addDisasterPreventionPoint);
                case 'D200': // 河川カメラ
                    return this.addGeoJSONLayerWithStatus(layerInfo, opacity,
                        '/data/camera/cctv.geojson', this.addCameraPoint);

                default:
                    return;
            }
        },

        /**
         * 処理名：状況データを付与したレイヤー追加:1つのGeoJSON
         * 処理概要：1つのGeoJSONで表される地物に対して状況データを組み合わせたレイヤーを追加する。
         *
         * @param {Object} layerInfo  レイヤー情報
         * @param {Number} opacity    透過度
         * @param {String} geojsonUrl 地物を表すGeoJSONのURL
         * @param {function} additionalFunc (optional)レイヤー追加など、別途実施したいfunctionを指定
         * @returns {Promise} 追加処理が終わったら解決するPromise
         */
        addGeoJSONLayerWithStatus: function (layerInfo, opacity, geojsonUrl, additionalFunc) {
            var id = layerInfo.id;

            return all({
                // layerUrlに指定されたLアラート一覧ファイルをIDに対応するディレクトリーから取得
                data: Requester.get(_parseUrl(layerInfo.layerUrl, id)),

                // ベースとなるgeojsonファイルはキャッシュを効かせる
                baseLayer: Requester.get(geojsonUrl, { preventCache: false }),

                // layerInfoがstyleを持っている場合は取得
                style: layerInfo.styleUrl && this._getStyle(_parseUrl(layerInfo.styleUrl, id))

            }).then(lang.hitch(this, function (result) {

                // styleオブジェクトに状況データを埋め込む
                if (result.data) {
                    result.style.geojsonOptions.status = result.data;
                }

                // そのままだと style()などで 'lAlert' が見えないので、bindしてthisをこのオブジェクトに向かせる
                if (result.style.geojsonOptions.style) {
                    result.style.geojsonOptions.style =
                        result.style.geojsonOptions.style.bind(result.style.geojsonOptions);
                }

                if (result.style.geojsonOptions.filter) {
                    result.style.geojsonOptions.filter =
                        result.style.geojsonOptions.filter.bind(result.style.geojsonOptions);
                }

                if (result.style.geojsonOptions.pointToLayer) {
                    result.style.geojsonOptions.pointToLayer =
                        result.style.geojsonOptions.pointToLayer.bind(result.style.geojsonOptions);
                }

                var options = result.style && result.style.geojsonOptions;
                var layer = L.geoJson(result.baseLayer, options);

                // 塗り潰しパターンを用いる場合はレイヤー追加・削除時に地図に反映する
                var patternData = options.patternData;
                if (patternData) {
                    // 関数の場合は引数なしで実行した結果を使う
                    if (lang.isFunction(patternData)) {
                        patternData = options.patternData();
                    }
                    // レイヤー追加時にパターンを追加
                    aspect.before(layer, 'onAdd', function (map) {
                        df.forEach(patternData, function (pattern) {
                            pattern.addTo(map);
                        });
                    });
                    // レイヤー削除時にパターンを除去
                    aspect.before(layer, 'onRemove', function (map) {
                        df.forEach(patternData, function (pattern) {
                            pattern.removeFrom(map);
                        });
                    });
                }

                this.layers[id] = layer.addTo(this.map);
                // 透過度の設定
                this.layers[id].options.opacity = opacity;
                this.setDefaultFillOpacity(layer);
                this.setLayerOpacity(layer, opacity);

                // 情報種別の設定
                this.layers[id].options.infoCategoryCd = layerInfo.infoCategoryCd;
                // Lアラートの場合、alert.jsonに入っている'lalertFilesId'をレイヤーのオプションに組み込む
                if (result.data.lalertFilesId) {
                    this.layers[id].options.lalertFilesId = result.data.lalertFilesId;
                }

                // 引数で指定された追加処理を実施
                if (additionalFunc) {
                    additionalFunc.call(this, {
                        result: result,
                        layer: layer
                    });
                }

                // 前面表示
                this.toFront(id);
            }), function (error) {
                console.error(error);
            });
        },

        /**
         * 処理名：状況データを付与したレイヤー追加:1つのGeoJSON
         * 処理概要：複数のGeoJSONで表される地物に対して状況データを組み合わせたレイヤーを追加する。
         *
         * @param {Object} layerInfo  レイヤー情報
         * @param {Number} opacity    透過度
         * @param {Object} geojsonUrl 地物を表すGeoJSONのURL、`{key: url} で指定`
         * @param {function} additionalFunc (optional)レイヤー追加など、別途実施したいfunctionを指定
         * @returns {Promise} 追加処理が終わったら解決するPromise
         */
        addMultipulGeoJSONLayerWithStatus: function (layerInfo, opacity, geojsonUrl, additionalFunc) {
            var id = layerInfo.id;

            var promises = {
                // layerUrlに指定されたLアラート一覧ファイルをIDに対応するディレクトリーから取得
                data: Requester.get(_parseUrl(layerInfo.layerUrl, id)),

                // layerInfoがstyleを持っている場合は取得
                style: layerInfo.styleUrl && this._getStyle(_parseUrl(layerInfo.styleUrl, id))
            };

            // 複数指定されたgeojsonのURLを解決するpromisesに追加
            for (var key in geojsonUrl) {
                if (geojsonUrl.hasOwnProperty(key)) {
                    promises[key] = Requester.get(geojsonUrl[key], { preventCache: false });
                }
            }

            return all(promises).then(lang.hitch(this, function (result) {

                // styleオブジェクトに状況データを埋め込む
                if (result.data) {
                    result.style.geojsonOptions.status = result.data;
                }

                // そのままだと style()などで 'lAlert' が見えないので、bindしてthisをこのオブジェクトに向かせる
                if (result.style.geojsonOptions.style) {
                    result.style.geojsonOptions.style =
                        result.style.geojsonOptions.style.bind(result.style.geojsonOptions);
                }

                if (result.style.geojsonOptions.filter) {
                    result.style.geojsonOptions.filter =
                        result.style.geojsonOptions.filter.bind(result.style.geojsonOptions);
                }

                if (result.style.geojsonOptions.pointToLayer) {
                    result.style.geojsonOptions.pointToLayer =
                        result.style.geojsonOptions.pointToLayer.bind(result.style.geojsonOptions);
                }

                var options = result.style && result.style.geojsonOptions;

                // 空でgeojsonレイヤーを作る
                var layer = L.geoJson({}, options);

                // 複数のgeojsonデータを追加していく
                for (var key in geojsonUrl) {
                    if (geojsonUrl.hasOwnProperty(key)) {
                        layer.addData(result[key]);
                    }
                }

                this.layers[id] = layer.addTo(this.map);
                // 透過度の設定
                this.layers[id].options.opacity = opacity;
                this.setDefaultFillOpacity(this.layers[id]);

                // 情報種別の設定
                this.layers[id].options.infoCategoryCd = layerInfo.infoCategoryCd;
                // Lアラートの場合、alert.jsonに入っている'lalertFilesId'をレイヤーのオプションに組み込む
                if (result.data.lalertFilesId) {
                    this.layers[id].options.lalertFilesId = result.data.lalertFilesId;
                }

                // 引数で指定された追加処理を実施
                if (additionalFunc) {
                    additionalFunc.call(this, {
                        result: result,
                        layer: layer
                    });
                }

                // 前面表示
                this.toFront(id);
            }));
        },

        /**
         * 処理名：状況データを付与したレイヤー追加:タイル化GeoJSON
         * 処理概要：タイル化されたGeoJSONで表される地物に対してLアラートの警報情報を組み合わせたレイヤーを追加する。
         *
         * @param {Object} layerInfo  レイヤー情報
         * @param {Number} opacity    透過度
         * @param {String} geojsonUrl 地物を表すGeoJSONのURL
         * @param {function} additionalFunc (optional)レイヤー追加など、別途実施したいfunctionを指定
         * @returns {Promise} 追加処理が終わったら解決するPromise
         */
        addTiledGeoJSONLayerWithStatus: function (layerInfo, opacity, geojsonUrl, additionalFunc) {
            var id = layerInfo.id;

            return all({
                // layerUrlに指定されたLアラート一覧ファイルをIDに対応するディレクトリーから取得
                data: Requester.get(_parseUrl(layerInfo.layerUrl, id)),

                // layerInfoがstyleを持っている場合は取得
                style: layerInfo.styleUrl && this._getStyle(_parseUrl(layerInfo.styleUrl, id))

            }).then(lang.hitch(this, function (result) {

                // styleオブジェクトに状況データを埋め込む
                if (result.data) {
                    result.style.geojsonOptions.status = result.data;
                }

                // そのままだと style()などで 'lAlert' が見えないので、bindしてthisをこのオブジェクトに向かせる
                if (result.style.geojsonOptions.style) {
                    result.style.geojsonOptions.style =
                        result.style.geojsonOptions.style.bind(result.style.geojsonOptions);
                }

                if (result.style.geojsonOptions.filter) {
                    result.style.geojsonOptions.filter =
                        result.style.geojsonOptions.filter.bind(result.style.geojsonOptions);
                }

                if (result.style.geojsonOptions.pointToLayer) {
                    result.style.geojsonOptions.pointToLayer =
                        result.style.geojsonOptions.pointToLayer.bind(result.style.geojsonOptions);
                }

                var options = result.style && result.style.geojsonOptions;
                var layer = new L.GeoJSONTileLayer(geojsonUrl, result.style.options, options);
                layer.options.opacity = opacity;
                this.setDefaultFillOpacity(this.layers[id]);
                // 情報種別の設定
                layer.options.infoCategoryCd = layerInfo.infoCategoryCd;
                // Lアラートの場合、alert.jsonに入っている'lalertFilesId'をレイヤーのオプションに組み込む
                if (result.data.lalertFilesId) {
                    layer.options.lalertFilesId = result.data.lalertFilesId;
                }

                this.addLayer(layer, id);

                // 引数で指定された追加処理を実施
                if (additionalFunc) {
                    additionalFunc.call(this, {
                        result: result,
                        layer: layer
                    });
                }

                // 前面表示
                this.toFront(id);

            }));
        },

        /**
         * 処理名：状況データを付与したレイヤー追加:1つのJSON
         * 処理概要：1つのJSONで表される地物に対して状況データを組み合わせたレイヤーを追加する
         *          GeoJSONではなく、緯度経度をプロパティとして持つJSONを用いる
         *          style.jsによるスタイル指定を使いたいため、一旦JSONをGeoJSON化してレイヤーを生成する
         *
         * @param {Object} layerInfo  レイヤー情報
         * @param {Number} opacity    透過度
         * @param {function} additionalFunc (optional)レイヤー追加など、別途実施したいfunctionを指定
         * @returns {Promise} 追加処理が終わったら解決するPromise
         */
        addJSONLayerWithStatus: function (layerInfo, opacity, additionalFunc) {
            var id = layerInfo.id;

            return all({
                data: Requester.get(_parseUrl(layerInfo.layerUrl, id)),

                // layerInfoがstyleを持っている場合は取得
                style: layerInfo.styleUrl && this._getStyle(_parseUrl(layerInfo.styleUrl, id))

            }).then(lang.hitch(this, function (result) {
                var geojson = {};
                // JSONからGeoJSONを生成
                if (result.data) {
                    geojson = this._generateGeoJson(result.data);
                }

                var options = result.style && result.style.geojsonOptions;
                var layer = L.geoJson(geojson, options);
                this.layers[id] = layer.addTo(this.map);
                // 透過度の設定
                this.layers[id].options.opacity = opacity;
                this.setDefaultFillOpacity(this.layers[id]);

                // 情報種別の設定
                this.layers[id].options.infoCategoryCd = layerInfo.infoCategoryCd;
                // Lアラートの場合、alert.jsonに入っている'lalertFilesId'をレイヤーのオプションに組み込む
                if (result.data.lalertFilesId) {
                    this.layers[id].options.lalertFilesId = result.data.lalertFilesId;
                }

                // 引数で指定された追加処理を実施
                if (additionalFunc) {
                    additionalFunc.call(this, {
                        result: result,
                        layer: layer
                    });
                }

                // 前面表示
                this.toFront(id);
            }));
        },

        /**
         * pointのみを対象とする
         * @param  {[type]} data [description]
         * @return {[type]}      [description]
         */
        _generateGeoJson: function (data) {
            var geojson = {
                type: 'FeatureCollection',
                features: []
            };

            // lalertsの配列にに緯度経度と各種プロパティが入っているので、GeoJSON形式にバラす
            data.lalerts.forEach(function (alert, i) {
                var feature = {
                    geometry: {
                        type: 'Point',
                        'coordinates': [alert.longitude, alert.latitude]
                    },
                    type: 'Feature',
                    id: i + 1,
                    properties: {
                    }
                };

                // すべてのプロパティをfeature.propertiesに入れてしまう
                for (var key in alert) {
                    if (alert.hasOwnProperty(key)) {
                        feature.properties[key] = alert[key];
                    }
                }

                geojson.features.push(feature);
            });

            return geojson;
        },

        /**
         * 震源の位置にマーカーを打つ
         * @param {Object} obj マーカーを入れるレイヤー(layer)
         *                     震源情報が含まれるアラート(results.lalerts)
         *                     が入っている必要がある
         */
        addHypocenter: function (obj) {
            if (!obj.result.data.lalerts.hypocenter) {
                return;
            }

            var marker = this.hypocenterMarker(obj.result.data.lalerts.hypocenter);

            // geojsonとGeoJSONTileLayerでオブジェクトの構成が違うので、addLayerできるプロパティを切り替える
            var layer = obj.layer.geojsonLayer ? obj.layer.geojsonLayer : obj.layer;
            layer.addLayer(marker);

            // 作ったマーカーをgeojsonレイヤーにaddする処理
            // クロージャでマーカーとレイヤーのオブジェクトを閉じ込める
            var func = (function () {
                return function () {
                    if (!layer.hasLayer(marker)) {
                        layer.addLayer(marker);
                    }
                };
            })();

            this.map.on('zoomend', func);

            // TODO: GeoJSONTileLayerでlayerremoveイベントが飛んでないので
            // この処理が動いてない
            obj.layer.on('layerremove', lang.hitch(this, function () {
                this.map.off(func);
            }));
        },

        /**
         * 震源のマーカーオブジェクトを生成する
         * @param  {Object} hypocenter 震源情報
         * @return {Marker}            震源に表示するマーカーオブジェクト
         */
        hypocenterMarker: function (hypocenter) {
            var marker = L.marker([hypocenter.earthCordinateLatitude, hypocenter.earthCordinateLongitude], {
                icon: L.icon({
                    iconUrl: '/images/lalert/455.png',
                    iconSize: [20, 20],
                    iconAnchor: [10, 10]
                }),
                zIndexOffset: 1000
            });

            var content = '<table><tr><td colspan=2 style="color:#FF0000"><b>地震情報</b></td></tr>' +
                '<tr><td style="color:#0000FF;padding-right:1em;">地震発生時刻</td><td>' +
                hypocenter.arrivalTime + '</td></tr>' +
                '<tr><td style="color:#0000FF;padding-right:1em;">震源</td><td>' +
                hypocenter.earthAreaname + '</td></tr>' +
                '<tr><td style="color:#0000FF;padding-right:1em;">震源位置</td><td>' +
                hypocenter.earthCordinateDescription + '</td></tr>' +
                '<tr><td style="color:#0000FF;padding-right:1em;">マグニチュード</td><td>' +
                hypocenter.earthMagnitude + '</td></tr></table>';

            marker.bindPopup(content, { maxWidth: 1000, closeButton: false });

            return marker;
        },

        /**
         * 警戒対象の火山の位置にマーカーを打つ
         * @param {Object} obj マーカーを入れるレイヤー(layer)
         *                     火山情報が含まれるアラート(results.lalerts)
         *                     が入っている必要がある
         */
        addMountainPoint: function (obj) {
            if (!obj.result.data.lalerts.mountain) {
                return;
            }

            var marker = this.mountainPointMarker(obj.result.data.lalerts.mountain);

            // geojsonとGeoJSONTileLayerでオブジェクトの構成が違うので、addLayerできるプロパティを切り替える
            var layer = obj.layer.geojsonLayer ? obj.layer.geojsonLayer : obj.layer;
            layer.addLayer(marker);
        },

        /**
         * 大阪市庁舎の位置にマーカーを打つ
         * @param {Object} obj マーカーを入れるレイヤー(layer)
         *                     大阪市の本部設置状況が含まれる必要がある
         */
        addDisasterPreventionPoint: function (obj) {
            if (obj.result.data.items) {
                var layer = obj.layer.geojsonLayer ? obj.layer.geojsonLayer : obj.layer;
                array.forEach(obj.result.data.items, function (dpObj) {
                    if (dpObj.municipalityCd === config.municInfo.cityMunicCd) {
                        if (dpObj.hqSetFlg === '1') {
                            // 設置の場合のみ
                            var marker = this.disasterPreventionPointMarker(dpObj);
                            layer.addLayer(marker);
                        }
                    }
                }, this);
            }
        },

        addCameraPoint: function (obj) {
            var layer = obj.layer.geojsonLayer ? obj.layer.geojsonLayer : obj.layer;
            array.forEach(obj.result.data.features, function (cameraFeature) {
                var marker = this.cameraPointMarker(cameraFeature);
                // geojsonとGeoJSONTileLayerでオブジェクトの構成が違うので、addLayerできるプロパティを切り替える
                layer.addLayer(marker);
            }, this);

            layer.on('mouseover', lang.hitch(this, function (e) {
                console.log(e.layer.feature);

                var cameraFeature = e.layer.feature;

                // ポップアップの中身を生成する。
                var content = '<div><b>' + cameraFeature.properties.name + '</b>';
                if (cameraFeature.properties.nameKana) {
                    content += '<br>（' + cameraFeature.properties.nameKana + '）';
                }
                if (cameraFeature.properties.type) {
                    content += '<br>種類：' + cameraFeature.properties.type + 'カメラ';
                }
                content += '<br>所轄：' + cameraFeature.properties.manager + '<br>';
                console.log(cameraFeature.properties.linkUrl);

                var detailUrl = cameraFeature.properties.linkUrl ?
                    cameraFeature.properties.linkUrl :
                    cameraFeature.properties.isMovie ? cameraFeature.properties.path : null;
                var imageUrl = cameraFeature.properties.isDatetimeRequired ?
                    cameraFeature.properties.path + new Date().getTime :
                    cameraFeature.properties.path;

                if (!cameraFeature.properties.path && !cameraFeature.properties.linkUrl) {
                    content += '公開手続中<br>';
                } else {
                    content += detailUrl ?
                        '<a href=' + cameraFeature.properties.linkUrl + ' target="_blank">＞詳細情報を表示</a>' :
                        '<img src =' + imageUrl + ' width="300px;"></a><br>' +
                        '<a href=' + imageUrl + ' target="_blank">＞画像を拡大</a>';
                }

                content += '<br>市町村：' + cameraFeature.properties.municipalityName;
                content += '<br>住所：' + cameraFeature.properties.address;
                if (cameraFeature.properties.rivername) {
                    content += '<br>河川名：' + cameraFeature.properties.rivername;
                }
                if (cameraFeature.properties.location) {
                    content += '<br>位置：' + cameraFeature.properties.location;
                }

                content += '</div>';

                var layerPopup = L.popup()
                    .setLatLng(e.latlng)
                    .setContent(content)
                    .openOn(this.map);
                console.log(layerPopup);
            }));
        },

        /**
         * 火山のマーカーオブジェクトを生成する
         * @param  {Object} mountainPoint 火山の位置を示すレイヤー
         * @return {Marker}            火山に表示するマーカーオブジェクト
         */
        mountainPointMarker: function (mountain) {
            var marker = L.marker([mountain.latitude, mountain.longitude], {
                icon: L.icon({
                    iconUrl: '/images/lalert/445.png',
                    iconSize: [20, 20],
                    iconAnchor: [10, 10]
                })
            });

            var content = '<table><tr><td colspan=2 style="color:#FF0000"><b>火山情報</b></td></tr>' +
                '<tr><td style="color:#0000FF;padding-right:1em;">火山名</td><td>' +
                mountain.name + '</td></tr>' +
                '<tr><td style="color:#0000FF;padding-right:1em;">警戒レベル</td><td>' +
                mountain.kindName + '</td></tr></table>';

            marker.bindPopup(content, { maxWidth: 1000, closeButton: false });

            return marker;
        },

        /**
         * 本部設置状況のマーカーオブジェクトを生成する
         * @param  {Object} mountainPoint 市庁舎の位置を示すレイヤー
         * @return {Marker}            市庁舎に表示するマーカーオブジェクト
         */
        disasterPreventionPointMarker: function (obj) {
            var iconImage;
            // 本部種別に応じて出すアイコンを変える
            if (obj.hqName === '災害対策本部') {
                iconImage = '/images/draw/icon/076.png';
            } else {
                iconImage = '/images/draw/icon/077.png';
            }
            var marker = L.marker([config.map.latitude, config.map.longitude], {
                icon: L.icon({
                    iconUrl: iconImage,
                    iconSize: [20, 20],
                    iconAnchor: [10, 10]
                })
            });

            var content = '<table><tr><td colspan=2 style="color:#FF0000"><b>本部設置状況</b></td></tr>' +
                '<tr><td style="color:#0000FF;padding-right:1em;">本部種別</td><td>' +
                obj.hqName + '</td></tr>' +
                '<tr><td style="color:#0000FF;padding-right:1em;">動員種別</td><td>' +
                obj.statusName + '</td></tr></table>';

            marker.bindPopup(content, { maxWidth: 1000, closeButton: false });

            return marker;
        },

        cameraPointMarker: function (cameraFeature) {
            var marker = L.marker(cameraFeature.geometry.coordinates, {
                icon: L.icon({
                    iconUrl: '/images/draw/icon/180.png',
                    iconSize: [20, 20],
                    iconAnchor: [10, 10]
                })
            });

            return marker;
        },

        /**
         * 警戒対象の水位観測所の位置にマーカーを打つ
         * @param {Object} obj マーカーを入れるレイヤー(layer)
         *                     観測所情報が含まれるアラート(results.lalerts)
         *                     が入っている必要がある
         */
        addRiverObservationPoint: function (obj) {
            if (!obj.result.data.lalerts.points) {
                return;
            }

            obj.result.data.lalerts.points.forEach(lang.hitch(this, function (point) {
                var marker = this.riverObservationPointMarker(point);

                // geojsonとGeoJSONTileLayerでオブジェクトの構成が違うので、addLayerできるプロパティを切り替える
                var layer = obj.layer.geojsonLayer ? obj.layer.geojsonLayer : obj.layer;
                layer.addLayer(marker);
            }));
        },

        /**
         * 水位観測点のマーカーオブジェクトを生成する
         * @param  {Object} point 観測点の位置を示すレイヤー
         * @return {Marker}            観測点に表示するマーカーオブジェクト
         */
        riverObservationPointMarker: function (point) {
            var marker = L.marker([point.latitude, point.longitude], {
                icon: L.icon({
                    iconUrl: '/images/lalert/1104.png',
                    iconSize: [20, 20],
                    iconAnchor: [10, 10]
                })
            });

            var content = '<table><tr><td colspan=2 style="color:#FF0000"><b>水位観測点情報</b></td></tr>' +
                '<tr><td style="color:#0000FF;padding-right:1em;">観測点名</td><td>' +
                point.kasenStationName + '</td></tr></table>';

            marker.bindPopup(content, { maxWidth: 1000, closeButton: false });

            return marker;
        },

        /**
         * 観測情報など動的に状況を表す情報が切り替わるレイヤーを追加
         * @param {Object} layerInfo  レイヤー情報
         * @param {Number} opacity    透過度
         * @param {function} additionalFunc (optional)レイヤー追加など、別途実施したいfunctionを指定
         * @returns {Promise} 追加処理が終わったら解決するPromise
         */
        addObservationLayer: function (layerInfo, opacity, additionalFunc) {
            var id = layerInfo.id;

            // layerInfoに動的となるURLや地物種類を入れる
            switch (layerInfo.infoCategoryCd) {
                case 'O001': // 観測情報: 10分雨量
                case 'O011': // 60分雨量
                // case 'O012': // 累加雨量
                //     layerInfo.dynamic = {
                //         store: RainfallObservatoryStore,
                //         statusUrl: '/data/rainfall/list',
                //         type: 'Point'
                //     };
                //     break;
                case 'O002': // 観測情報: 河川水位
                    layerInfo.dynamic = {
                        store: RiverLevelObservatoryStore,
                        statusUrl: '/data/river/list',
                        type: 'Point'
                    };
                    break;
                // case 'O015': // 危機管理型水位
                //     layerInfo.dynamic = {
                //         store: CrisisManageRiverLevelObsavatoryStore,
                //         statusUrl: '/data/crisisManageRiverLevel/list',
                //         type: 'Point'
                //     };
                //     break;
                // case 'O003': // 観測情報: 潮位
                //     layerInfo.dynamic = {
                //         store: TideLevelObservatoryStore,
                //         statusUrl: '/data/tide/list',
                //         type: 'Point'
                //     };
                //     break;
                // case 'O004': // 観測情報: ダム
                //     layerInfo.dynamic = {
                //         store: DamObservatoryStore,
                //         statusUrl: '/data/damQuantities/list',
                //         type: 'Point'
                //     };
                //     break;
                // case 'O007': // 観測情報: 流域平均雨量
                //     layerInfo.dynamic = {
                //         store: BasinObservatoryStore,
                //         statusUrl: '/data/basin/list',
                //         type: 'Point'
                //     };
                //     break;
                // case 'O013': // 観測情報: 市内気象情報
                //     layerInfo.dynamic = {
                //         store: LocalWeatherObservatoryStore,
                //         statusUrl: '/data/localWeather/list',
                //         type: 'Point'
                //     };
                //     break;
                default:
                    break;
            }

            return all({
                // layerInfoがstyleを持っている場合は取得
                style: layerInfo.styleUrl && this._getStyle(_parseUrl(layerInfo.styleUrl, id))

            }).then(lang.hitch(this, function (result) {
                var options = result.style && result.style.geojsonOptions;

                var layer = new ObservationLayer(layerInfo, options);
                this.layers[id] = layer.addTo(this.map);
                // 透過度の設定
                this.layers[id].options.opacity = opacity;
                this.setDefaultFillOpacity(this.layers[id]);

                // 情報種別の設定
                this.layers[id].options.infoCategoryCd = layerInfo.infoCategoryCd;

                // 引数で指定された追加処理を実施
                if (additionalFunc) {
                    additionalFunc.call(this, {
                        result: result,
                        layer: layer
                    });
                }

                // 前面表示
                this.toFront(id);
            }));
        },

        /**
         * 行政界レイヤーを追加
         * 行政界レイヤーは常に一番下に表示される
         * @param {Object} layerInfo  レイヤー情報
         * @param {Number} opacity    透過度
         * @param {function} additionalFunc (optional)レイヤー追加など、別途実施したいfunctionを指定
         * @returns {Promise} 追加処理が終わったら解決するPromise
         */
        addMunicipalityLayer: function (layerInfo, opacity) {
            var id = layerInfo.id;
            return all({
                // layerUrlに指定されたファイルをIDに対応するディレクトリーから取得
                data: Requester.get(_parseUrl(layerInfo.layerUrl, id)),
                // layerInfoがstyleを持っている場合は取得
                style: layerInfo.styleUrl && this._getStyle(_parseUrl(layerInfo.styleUrl, id))
            }).then(lang.hitch(this, function (result) {
                // スタイルつきGeoJSONならTanJSONとして、それ以外なら通常のGeoJSONとしてレイヤー化
                var options = result.style && result.style.geojsonOptions;
                var layer = (_isTanJson(result.data) ? L.tanJson : L.geoJson)(result.data, options);
                this.layers[id] = layer.addTo(this.map);
                if (this.checkZoomSwitchTarget(layerInfo, layer)) {
                    this.map.removeLayer(layer);
                }
                // 透過度の設定
                this.layers[id].options.opacity = opacity;
                this.setDefaultFillOpacity(this.layers[id]);

                // 情報種別の設定
                this.layers[id].options.infoCategoryCd = layerInfo.infoCategoryCd;
                // 最背面に表示
                this.toBack(id);
            }));
        },

        /**
         * 避難所状況レイヤーを追加する
         * FIXME addObservationLayerと共通にしてもよいかも?
         */
        addShelterLayer: function (layerInfo, opacity, additionalFunc) {
            var id = layerInfo.id;

            // layerInfoに動的となるURLや地物種類を入れる
            switch (layerInfo.infoCategoryCd) {
                case 'D115': // 避難所状況
                    layerInfo.dynamic = {
                        statusUrl: '/data/shelter/shelter.json',
                        type: 'Point'
                    };
                    break;
                default:
                    break;
            }

            return all({
                // layerInfoがstyleを持っている場合は取得
                style: layerInfo.styleUrl && this._getStyle(_parseUrl(layerInfo.styleUrl, id))
            }).then(lang.hitch(this, function (result) {
                var options = result.style && result.style.geojsonOptions;
                var layer = new ShelterLayer(layerInfo, options);
                //this.layers[id] = layer.addTo(this.map);
                this.addLayer(layer, id);
                // 透過度の設定
                this.layers[id].options.opacity = opacity;
                this.setDefaultFillOpacity(this.layers[id]);

                // 情報種別の設定
                this.layers[id].options.infoCategoryCd = layerInfo.infoCategoryCd;

                // 引数で指定された追加処理を実施
                if (additionalFunc) {
                    additionalFunc.call(this, {
                        result: result,
                        layer: layer
                    });
                }

                // 前面表示
                this.toFront(id);
            }));
        },

        /**
         * 土砂災害危険度情報レイヤーを追加する
         * FIXME addObservationLayerと共通にしてもよいかも?
         */
        addSedimentWarningLayer: function (layerInfo, opacity) {
            var id = layerInfo.id;

            // layerInfoに動的となるURLや地物種類を入れる
            switch (layerInfo.infoCategoryCd) {
                case 'O005': // 土砂災害危険度情報(地区)
                    layerInfo.dynamic = {
                        statusUrl: '/data/sediment/area',
                        masterUrl: '/data/layer/data/lalert/area/sediment-area.geojson',
                        idProperty: 'districtID'
                    };
                    break;
                case 'O006': // 土砂災害危険度情報(メッシュ)
                    layerInfo.dynamic = {
                        statusUrl: '/data/sediment/mesh',
                        masterUrl: '/data/layer/data/lalert/area/sediment-mesh.geojson',
                        idProperty: 'mcode'
                    };
                    break;
                case 'O009': // 土壌雨量指数(実況)
                    layerInfo.dynamic = {
                        statusUrl: '/data/weather-mesh/srf/current',
                        masterUrl: '/data/layer/data/lalert/area/srf-mesh.geojson',
                        idProperty: 'mcode'
                    };
                    break;
                case 'O010': // 土壌雨量指数(予想)
                    layerInfo.dynamic = {
                        statusUrl: '/data/weather-mesh/srf/forecast',
                        masterUrl: '/data/layer/data/lalert/area/srf-mesh.geojson',
                        idProperty: 'mcode'
                    };
                    break;
                default:
                    break;
            }

            return all({
                // layerInfoがstyleを持っている場合は取得
                style: layerInfo.styleUrl && this._getStyle(_parseUrl(layerInfo.styleUrl, id))
            }).then(lang.hitch(this, function (result) {
                var options = result.style && result.style.geojsonOptions;
                var layer = new SedimentWarningLayer(layerInfo, options);
                //this.layers[id] = layer.addTo(this.map);
                this.addLayer(layer, id);
                // 透過度の設定
                this.layers[id].options.opacity = opacity;
                this.setDefaultFillOpacity(this.layers[id]);

                // 情報種別の設定
                this.layers[id].options.infoCategoryCd = layerInfo.infoCategoryCd;

                // 前面表示
                this.toFront(id);
            }));
        },

        /**
         * 処理名：観測情報用画像タイルレイヤー追加。
         * 処理概要：タイルURLからレイヤーを追加する。
         *
         * @param {Object} layerInfo レイヤー情報
         * @param {identifier} layerInfo.id レイヤーの識別子
         * @param {string} layerInfo.layerUrl 各タイルのURLを表すテンプレート文字列
         * @param {number} opacity 透過度
         * @returns {Promise} 追加処理が終わったら解決するPromise
         */
        addObservationRasterLayer: function (layerInfo, opacity) {
            var id = layerInfo.id;

            // 種別によっては読むディレクトリ名にsuffixがつく場合がある
            switch (layerInfo.infoCategoryCd) {
                case 'O008':
                    layerInfo.directorySuffix = '01';
                    layerInfo.timespan = 10;
                    break;
                case 'O009':
                    layerInfo.directorySuffix = '02';
                    layerInfo.timespan = 30;
                    break;
                case 'O010':
                    layerInfo.directorySuffix = '03';
                    layerInfo.timespan = 30;
                    break;
            }

            // オプションに透過度、オーバーズーミング設定を加える
            var options = lang.mixin(null, layerInfo, { opacity: opacity });
            options.maxNativeZoom = layerInfo.overlayZoom;
            this.layers[id] = new ObservationRasterLayer(layerInfo, options);
            // レイヤー追加
            this.layers[id].addTo(this.map);
            // 初期レイヤー透過度変更
            this.setDefaultFillOpacity(this.layers[id]);
            this.setLayerOpacity(this.layers[id], 0.6);
            // 前面表示
            this.toFront(id);
            return new Deferred().resolve();
        },


        /**
         * 処理名：被害JSONレイヤー追加。
         * 処理概要：GeoJSONファイルからレイヤーを追加する。
         *
         * @param {Object} layerInfo レイヤー情報
         * @param {identifier} layerInfo.id レイヤーの識別子
         * @param {string} layerInfo.layerUrl レイヤーの各タイルの配置を決めるURL
         * @param {string} [layerInfo.styleUrl] レイヤーのstyle.jsが設置されているURL
         * @param {number} opacity 透過度
         * @returns {Promise} 追加処理が終わったら解決するPromise
         */
        addDamageReportLayer: function (layerInfo, opacity) {
            // 部隊活動でもこのjsonTypeを相乗りしてしまっているが、不具合が発生するためメソッドを分岐させる
            // TODO: 恒久的には、jsonType=1の不具合(作図がある場合にアイコンのstyleが無視される問題)を解決するか、
            // 部隊活動用のjsonTypeを定義する必要がある
            if (layerInfo.infoCategoryCd && layerInfo.infoCategoryCd === 'D120') {
                var rescueMethod = this.addRescueLayer(layerInfo, opacity);
                return rescueMethod;
            }

            var id = layerInfo.id;
            return all({
                // layerUrlに指定されたファイルをIDに対応するディレクトリーから取得
                data: Requester.get(_parseUrl(layerInfo.layerUrl, id)),
                // layerInfoがstyleを持っている場合は取得
                style: layerInfo.styleUrl && this._getStyle(_parseUrl(layerInfo.styleUrl, id))
            }).then(lang.hitch(this, function (result) {
                // スタイルつきGeoJSONならTanJSONとして、それ以外なら通常のGeoJSONとしてレイヤー化
                var options = result.style && result.style.geojsonOptions;

                //閲覧権限に関するfilterを定義
                var userMunicCd = UserInfo.getMunicipalityCd();
                var filterFlgMunic = false; // 非県ユーザ向けの権限制御

                // 閲覧権限フィルタをかける条件：システム管理者でない＋レイヤに市町コードがある＋当該市町のユーザでない
                // （補足：政令指定都市ユーザの場合、市内各区の情報は閲覧可能）
                if (!UserInfo.isSysAdmin() && layerInfo.municipalityCd &&
                    (!userMunicCd ||
                        (userMunicCd &&
                            (userMunicCd !== layerInfo.municipalityCd) &&
                            !(userMunicCd === config.municInfo.cityMunicCd &&
                                config.municInfo.wardList.indexOf(layerInfo.municipalityCd) !== -1)))) {
                    filterFlgMunic = true;
                }
                var layer = L.layerGroup();
                var integrationlayer = L.layerGroup(); // 被害統合のためのレイヤー情報を保持する
                if (_isTanJson(result.data)) {
                    var jsonData = array.filter(result.data.features, function (data) {
                        if (data && data.properties && data.properties._markerType) {
                            return data.properties._markerType;
                        }
                    });
                    layer = L.tanJson(jsonData, options);
                }
                layer.options = options;
                var layerPiece = null;
                var marker = [];
                array.forEach(this.damageMarkerGroupList, function (group) {
                    if (group && group.markerGroup) {
                        var markerLayerList = group.markerGroup.getLayers();
                        var oldMarkerLayer = array.filter(markerLayerList, function (layer) {
                            return layer.layerId === layerInfo.id;
                        });
                        array.forEach(oldMarkerLayer, function (removeLayer) {
                            // 古いレイヤーを削除
                            group.markerGroup.removeLayer(removeLayer);
                        });
                        group.ids = array.map(group.markerGroup.getLayers(), function (layer) { return layer.layerId; });
                    }
                });
                array.forEach(result.data.features, function (feature) {
                    // プロパティ情報がない場合 Skip
                    if (!feature) {
                        return false;
                    }
                    if (!feature.properties) {
                        return false;
                    }
                    // 親被害を持つものは、作図も含めて非表示にする
                    if (feature.properties.parentAdmNum) {
                        return false;
                    }
                    // 軽微・被害なしは表示しない
                    // ただし、被害情報投稿の場合は深刻度にかかわらず表示
                    if (feature.properties.damageType !== '13' && feature.properties.urgencyType === '0') {
                        return false;
                    }

                    // 職員以外は確定報のみ参照
                    if (UserInfo.getDamageViewType() === '4' &&
                        feature.properties.reportViewStatus < 3) {
                        return false;
                    }

                    // 人的被害の参照権限がない場合、参照不可
                    if (UserInfo.getHumanDamageView() === '0' &&
                        feature.properties.humanDamageFlg === '1') {
                        return false;
                    }

                    var isTanJson = false;
                    array.some(tanJsonKeys, function (key) {
                        if (feature.properties[key]) {
                            layer.addLayer(L.tanJson(feature));
                            isTanJson = true;
                        }
                    });
                    // 被害統合用のレイヤー情報を保持
                    if (isTanJson) {
                        layerPiece = L.tanJson(feature);
                    } else {
                        layerPiece = L.geoJson(feature, options);
                    }
                    if (feature.geometry.type !== 'Point') {
                        layer.addLayer(layerPiece);
                    } else if (feature.properties._markerType) {
                        // 作図の場合
                        layer.addLayer(layerPiece);
                    } else {

                        integrationlayer.addLayer(layerPiece);

                        if (this.drawnDamageIds.indexOf(feature.properties.damageReportId) !== -1) {
                            return false;
                        }
                        marker = options.pointToLayer(feature,
                            [feature.geometry.coordinates[1], feature.geometry.coordinates[0]]);
                        marker = options.onEachFeature(feature, marker);
                        marker.humanDamageFlg = feature.properties.humanDamageFlg;
                        marker.layerId = layerInfo.id;
                        // 市町村別にグループを分ける
                        var markerGroup;
                        array.forEach(this.damageMarkerGroupList, function (group) {
                            if (group.municipalityCd === feature.properties.municipalityCd) {
                                markerGroup = group.markerGroup;
                                this.map.removeLayer(markerGroup);
                                if (group.ids.indexOf(String(id)) !== -1) {
                                    group.ids.pop(String(id));
                                }
                                group.ids.push(String(id));
                            }
                        }, this);
                        if (!markerGroup) {
                            markerGroup = this.getMarkerGroup();
                            //追加したマーカーを管理
                            this.damageMarkerGroupList.push({
                                ids: [String(id)],
                                municipalityCd: feature.properties.municipalityCd,
                                markerGroup: markerGroup
                            });
                        }
                        markerGroup.addLayer(marker);
                        this.drawnDamageIds.push(feature.properties.damageReportId);
                    }
                }, this);

                array.forEach(this.damageMarkerGroupList, function (municipalityGroup) {
                    this.map._layersMaxZoom = 18;
                    this.map.addLayer(municipalityGroup.markerGroup);
                }, this);

                // 被害統合用のレイヤー情報をmapにセット
                this.integrationlayers[id] = integrationlayer;
                this.map.integrationlayers = this.integrationlayers;

                var checkLayer = layer._layers;
                for (let k in checkLayer) {
                    if (checkLayer[k].options.drawType === 'arrow') {
                        var latlngs = layer._layers[k].editing.latlngs[0].shift();
                        layer._layers[k].editing.latlngs[0].length = 1;
                        layer._layers[k]._latlngs = latlngs;
                    }
                }
                this.layers[id] = layer.addTo(this.map);
                this.addArrowHead2Map(layer);
                if (this.checkZoomSwitchTarget(layerInfo, layer)) {
                    this.map.removeLayer(layer);
                    this.removeArrowHeadFromMap(layer);
                }
                // 透過度の設定
                this.layers[id].options.opacity = opacity;
                this.setDefaultFillOpacity(layer);
                this.setLayerOpacity(layer, opacity);

                // 情報種別の設定
                this.layers[id].options.infoCategoryCd = layerInfo.infoCategoryCd;
                // 前面表示
                this.toFront(id);
            }));
        },

        /**
         * 処理名：部隊活動JSONレイヤー追加。
         * 処理概要：GeoJSONファイルからレイヤーを追加する。
         *
         * @param {Object} layerInfo レイヤー情報
         * @param {identifier} layerInfo.id レイヤーの識別子
         * @param {string} layerInfo.layerUrl レイヤーの各タイルの配置を決めるURL
         * @param {string} [layerInfo.styleUrl] レイヤーのstyle.jsが設置されているURL
         * @param {number} opacity 透過度
         * @returns {Promise} 追加処理が終わったら解決するPromise
         */
        addRescueLayer: function (layerInfo, opacity) {
            var id = layerInfo.id;
            return all({
                // layerUrlに指定されたファイルをIDに対応するディレクトリーから取得
                data: Requester.get(_parseUrl(layerInfo.layerUrl, id)),
                // layerInfoがstyleを持っている場合は取得
                style: layerInfo.styleUrl && this._getStyle(_parseUrl(layerInfo.styleUrl, id))
            }).then(lang.hitch(this, function (result) {
                // スタイルつきGeoJSONならTanJSONとして、それ以外なら通常のGeoJSONとしてレイヤー化
                var options = result.style && result.style.geojsonOptions;

                var layer = L.layerGroup();
                layer.options = options;
                var layerPiece = null;
                array.forEach(result.data.features, function (feature) {
                    var isTanJson = false;
                    array.some(tanJsonKeys, function (key) {
                        if (feature.properties[key]) {
                            isTanJson = true;
                        }
                    });
                    if (isTanJson) {
                        layerPiece = L.tanJson(feature);
                    } else {
                        layerPiece = L.geoJson(feature, options);
                    }
                    layer.addLayer(layerPiece);
                });

                this.layers[id] = layer.addTo(this.map);
                this.addArrowHead2Map(layer);
                if (this.checkZoomSwitchTarget(layerInfo, layer)) {
                    this.map.removeLayer(layer);
                    this.removeArrowHeadFromMap(layer);
                }
                // 透過度の設定
                this.layers[id].options.opacity = opacity;
                this.setDefaultFillOpacity(layer);
                this.setLayerOpacity(layer, opacity);

                // 情報種別の設定
                this.layers[id].options.infoCategoryCd = layerInfo.infoCategoryCd;
                // 前面表示
                this.toFront(id);
            }));
        },

        /**
         * マーカーグループの作成
         */
        getMarkerGroup: function () {
            return new L.markerClusterGroup({
                iconCreateFunction: function (cluster) {
                    var markers = cluster.getAllChildMarkers();
                    var humanDamageFlg = false;
                    dojo.some(markers, function (marker) {
                        if (marker.humanDamageFlg === '0') {
                            humanDamageFlg = true;
                            return false;
                        }
                    });
                    var color = 'rgba( 85, 176, 250, 0.7 )';
                    if (humanDamageFlg) {
                        color = 'rgba( 240, 105, 60, 0.7 )';
                    }
                    var radius = Math.min(cluster.getChildCount() * 10, 4 * 10);
                    var html = '';
                    html += '<div class="leaflet-marker-icon marker-cluster ';
                    html += 'marker-cluster-small leaflet-zoom-animated leaflet-clickable"';
                    html += 'style="margin-left: ' + (50 - radius) + 'px; margin-top: ' + (50 - radius) + 'px;';
                    html += 'font-size: ' + radius + 'px;';
                    html += 'line-height: ' + (radius * 2) + 'px;';
                    html += 'background-color: ' + color + ';';
                    html += 'width: ' + (radius * 2) + 'px; height: ' + (radius * 2) + 'px;';
                    html += 'border-radius:' + radius + 'px;';
                    html += '"><span>' + cluster.getChildCount() + '</span></div>';
                    return L.divIcon({
                        html: html,
                        className: 'marker-cluster',
                        iconSize: new L.Point(100, 100)
                    });
                }
            });
        },

        /**
         * 処理名：長周期地震動JSONレイヤー追加。
         * 処理概要：GeoJSONファイルからレイヤーを追加する。
         *
         * @param {Object} layerInfo レイヤー情報
         * @param {number} opacity 透過度
         * @returns {Promise} 追加処理が終わったら解決するPromise
         */
        addLongPeriodGroundMotionLayer: function(layerInfo, opacity) {
            // return this.addGeoJSONLayerWithStatus(layerInfo, opacity,
            //     '/data/layer/data/lalert/longPeriodGroundMotion/observation_points.geojson', this.addHypocenter);
            return this.addGeoJSONLayerWithStatus(layerInfo, opacity,
                '/data/layer/data/lalert/area/district.geojson', this.addHypocenter);

        },

        /**
         * 処理名：道路規制情報JSONレイヤー追加。
         * 処理概要：GeoJSONファイルからレイヤーを追加する。
         *
         * @param {Object} layerInfo レイヤー情報
         * @param {number} opacity 透過度
         * @returns {Promise} 追加処理が終わったら解決するPromise
         */
        addTrafficLayer: function (layerInfo, opacity) {
            var id = layerInfo.id;
            return all({
                // layerUrlに指定されたファイルをIDに対応するディレクトリーから取得
                data: Requester.get(_parseUrl(layerInfo.layerUrl, id)),
                // layerInfoがstyleを持っている場合は取得
                style: layerInfo.styleUrl && this._getStyle(_parseUrl(layerInfo.styleUrl, id))
            }).then(lang.hitch(this, function (result) {

                var addFeature = []; // 監視ページで表示するFeature
                var regFeature;
                var dmgFeature;
                var constFeature;
                var markerPosition;

                // LineStringを持つ規制区間と工事区間はそのまま監視ページで表示する
                // 被災区間は後続処理でマーカーのレイヤーを追加
                result.data.features.forEach(lang.hitch(this, function (feature) {
                    if (feature.properties.name === '規制区間') {
                        regFeature = feature;
                        addFeature.push(feature);
                    } else if (feature.properties.name === '被災区間') {
                        dmgFeature = feature;
                    } else if (feature.properties.name === '工事区間') {
                        constFeature = feature;
                        addFeature.push(feature);
                    }
                }));

                // 監視ページで表示するマーカーのFeatureを生成
                // マーカー表示位置は、工事の時は工事区間の中間点
                // それ以外の時は規制区間の中間点
                var feature;
                if (dmgFeature) {
                    // 災害
                    markerPosition = regFeature.properties.markerPosition;
                    feature = {
                        type: 'Feature',
                        properties: {
                            name: '被災区間',
                            roadName: dmgFeature.properties.roadName,
                            roadType: dmgFeature.properties.roadType,
                            dmgCategory: dmgFeature.properties.dmgCategory,
                            regContent: regFeature.properties.regContent,
                            pointName: dmgFeature.properties.pointName,
                            attachFile: dmgFeature.properties.attachFile,
                            trafficRegulationId: dmgFeature.properties.trafficRegulationId,
                            regStartTimestamp: regFeature.properties.regStartTimestamp,
                            isRegEnd: regFeature.properties.isRegEnd
                        },
                        geometry: {
                            type: 'Point',
                            coordinates: [markerPosition.lng, markerPosition.lat]
                        }
                    };
                } else if (constFeature) {
                    // 工事
                    markerPosition = constFeature.properties.markerPosition;
                    feature = {
                        type: 'Feature',
                        properties: {
                            name: '工事区間',
                            roadName: constFeature.properties.roadName,
                            roadType: constFeature.properties.roadType,
                            dmgCategory: constFeature.properties.dmgCategory,
                            regContent: regFeature.properties.regContent,
                            startPointName: constFeature.properties.startPointName,
                            endPointName: constFeature.properties.endPointName,
                            attachFile: constFeature.properties.attachFile,
                            trafficRegulationId: constFeature.properties.trafficRegulationId,
                            regStartTimestamp: regFeature.properties.regStartTimestamp,
                            isRegEnd: regFeature.properties.isRegEnd
                        },
                        geometry: {
                            type: 'Point',
                            coordinates: [markerPosition.lng, markerPosition.lat]
                        }
                    };
                } else {
                    // 事前、その他
                    markerPosition = regFeature.properties.markerPosition;
                    feature = {
                        type: 'Feature',
                        properties: {
                            name: '規制区間',
                            roadName: regFeature.properties.roadName,
                            roadType: regFeature.properties.roadType,
                            regReason: regFeature.properties.regReason,
                            regContent: regFeature.properties.regContent,
                            startPointName: regFeature.properties.startPointName,
                            endPointName: regFeature.properties.endPointName,
                            attachFile: regFeature.properties.attachFile,
                            trafficRegulationId: regFeature.properties.trafficRegulationId,
                            regStartTimestamp: regFeature.properties.regStartTimestamp,
                            isRegEnd: regFeature.properties.isRegEnd
                        },
                        geometry: {
                            type: 'Point',
                            coordinates: [markerPosition.lng, markerPosition.lat]
                        }
                    };
                }
                // Featureを生成
                addFeature.push(feature);

                // スタイルつきGeoJSONならTanJSONとして、それ以外なら通常のGeoJSONとしてレイヤー化
                var options = result.style && result.style.geojsonOptions;
                var layer = (_isTanJson(addFeature) ? L.tanJson : L.geoJson)(addFeature, options);
                this.layers[id] = layer.addTo(this.map);
                this.addArrowHead2Map(layer);
                if (this.checkZoomSwitchTarget(layerInfo, layer)) {
                    this.map.removeLayer(layer);
                    this.removeArrowHeadFromMap(layer);
                }
                // 透過度の設定
                this.layers[id].options.opacity = opacity;
                this.setDefaultFillOpacity(layer);
                this.setLayerOpacity(layer, opacity);

                // 情報種別の設定
                this.layers[id].options.infoCategoryCd = layerInfo.infoCategoryCd;
                // 前面表示
                this.toFront(id);
            }));
        },

        /**
         * 処理名：岡山県道路交通規制情報のレイヤー追加。
         * 処理概要：API経由で取得したデータからレイヤーを追加する。
         *
         * @param {Object} layerInfo レイヤー情報
         * @param {identifier} layerInfo.id レイヤーの識別子
         * @param {string} layerInfo.layerUrl レイヤーの各タイルの配置を決めるURL
         * @param {string} [layerInfo.styleUrl] レイヤーのstyle.jsが設置されているURL
         * @param {number} opacity 透過度
         * @returns {Promise} 追加処理が終わったら解決するPromise
         */
        addRoadInfoGeoJsonLayer: function (layerInfo, opacity) {
            var id = layerInfo.id;
            return all({
                // 道路交通規制情報を取得
                data: this.getRegulationGeojson(),
                // layerInfoがstyleを持っている場合は取得
                style: layerInfo.styleUrl && this._getStyle(_parseUrl(layerInfo.styleUrl, id))
            }).then(lang.hitch(this, function (result) {
                var options = result.style && result.style.geojsonOptions;
                var layer = L.geoJson(result.data, options);
                this.layers[id] = layer.addTo(this.map);

                // ポップアップを開く時に、リンクのクリックイベントを追加
                layer.on('popupopen', function () {
                    dojo.connect(document.getElementById('link'), 'onclick', function () {
                        var data;
                        var id = document.getElementById('id').innerHTML;
                        array.some(result.data.features, function (feature) {
                            if (feature.properties.id === id) {
                                data = feature.properties;
                                data.lat = feature.geometry.coordinates[1];
                                data.lng = feature.geometry.coordinates[0];
                                return true;
                            }
                        });
                        var href = [];
                        href.push('?p=report/register');
                        href.push('&id=' + data.id);
                        href.push('&roadName=' + data.roadName);
                        href.push('&lat=' + data.lat);
                        href.push('&lng=' + data.lng);
                        href.push('&kind=' + data.kind);
                        href.push('&kindName=' + data.kindName);
                        href.push('&address=' + data.address);
                        href.push('&reason=' + data.reason);
                        href.push('&period=' + data.period);
                        href.push('&management=' + data.management);
                        href.push('&note=' + data.note);
                        // 被害情報新規登録画面へ遷移
                        document.getElementById('link').href = href.join('');
                    });
                });

                // 透過度の設定
                this.layers[id].options.opacity = opacity;
                this.setDefaultFillOpacity(layer);
                this.setLayerOpacity(layer, opacity);

                // 情報種別の設定
                this.layers[id].options.infoCategoryCd = layerInfo.infoCategoryCd;
                // 前面表示
                this.toFront(id);
            }));
        },

        /**
         * 指定されたレイヤーを地図上の最前面へ移動。
         *
         * @param {identifier} id レイヤーの識別子
         */
        toFront: function (id) {
            // 無ければ何もしない
            if (!this.layers[id]) {
                console.warn(module.id + '#toFront: 指定されたidを持つレイヤーが登録されていません。');
                return;
            }
            // 最新レイヤーを前面表示
            if (this.layers[id].setZIndex) {
                this.layers[id].setZIndex(1);
            }
            // 元の位置から削除
            var index = this.displayOrder.indexOf(id);
            if (index !== -1) {
                this.displayOrder.splice(index, 1);
            }
            // 先頭に追加
            this.displayOrder.unshift(id);
        },

        /**
         * 処理名：表示レイヤー透過率変更。
         * 処理概要：表示レイヤーの透過率を変更する。
         *
         * @param {Object} layerInfo 表示レイヤー情報
         * @param {identifier} layerInfo.id レイヤーの識別子
         * @param {number} opacity 透過率
         * @return なし
         */
        changeLayerOpacity: function (layerInfo, opacity) {
            var layer = this.layers[layerInfo.id];
            var prop;

            if (layerInfo.dRealTimeFlag === '1') {
                // 動的配信
                if (layerInfo.jsonType === '3') {
                    layer.setOpacity(opacity);
                } else {
                    layer.options.opacity = opacity;
                    for (prop in layer._layers) {
                        if (!layer._layers.hasOwnProperty(prop)) {
                            continue;
                        }
                        this.setLayerOpacity(layer._layers[prop], opacity);
                    }
                }
            } else {
                if (layerInfo.jsonType === '1' || layerInfo.jsonType === '2') {
                    if (layerInfo.tileCreationFlag === '0') {
                        // 1: タンジェイソン
                        layer.options.opacity = opacity;
                        for (prop in layer._layers) {
                            if (!layer._layers.hasOwnProperty(prop)) {
                                continue;
                            }
                            this.setLayerOpacity(layer._layers[prop], opacity);
                        }
                    } else if (layerInfo.tileCreationFlag === '1') {
                        // 2: オルソジェイソン
                        layer.setOpacity(opacity);

                        for (prop in layer.geojsonLayer._layers) {
                            if (!layer.geojsonLayer._layers.hasOwnProperty(prop)) {
                                continue;
                            }
                            this.setLayerOpacity(layer.geojsonLayer._layers[prop], opacity);
                        }
                    }
                } else if (layerInfo.jsonType === '3') {
                    // 3: 画像タイル
                    layer.setOpacity(opacity);
                }
            }
        },

        /**
         * 処理名：各レイヤー透過率変更。
         * 処理概要：各レイヤーの透過率を変更する。
         *
         * @param {ILayer} layer レイヤー
         * @param {Number} opacity 透過率
         * @return なし
         */
        setLayerOpacity: function (layer, opacity) {
            if (typeof (layer.setOpacity) === 'function') {
                var _opacity = layer.defaultFillOpacity ? layer.defaultFillOpacity * opacity : opacity;
                layer.setOpacity(_opacity);
            } else if (!(layer instanceof L.LayerGroup) && typeof (layer.setStyle) === 'function') {
                // 単体のレイヤーであり、それがGeoJsonのベクターレイヤーだった場合
                layer.setStyle({ 'opacity': opacity });
                var fillOpacity = layer.defaultFillOpacity * opacity;
                layer.setStyle({ 'fillOpacity': fillOpacity });
            } else if (layer._layers) {
                for (var i in layer._layers) {
                    if (!layer._layers.hasOwnProperty(i)) {
                        continue;
                    }
                    this.setLayerOpacity(layer._layers[i], opacity);
                }
            }
        },

        /**
         * 各レイヤーのデフォルトのfillOpacityを取得する。
         *
         * @param {Object} layer leafletが生成したレイヤー
         */
        setDefaultFillOpacity: function (layer, opacity) {
            if (!layer._layers) {
                if (opacity === undefined) {
                    layer.defaultFillOpacity = layer.options.fillOpacity ?
                        json.parse(json.stringify(layer.options.fillOpacity)) : 1;
                } else {
                    layer.defaultFillOpacity = opacity;
                }
            } else {
                for (var i in layer._layers) {
                    if (!layer._layers.hasOwnProperty(i)) {
                        continue;
                    }
                    this.setDefaultFillOpacity(layer._layers[i], opacity);
                }
            }
        },

        /**
         * zoomLevelによってレイヤーの表示非表示を切り替えるかの確認をする。
         * @param {Object} layerInfo DBに格納されているレイヤー情報
         * @param {Object} layer     leafletが生成したレイヤー
         * @returns {boolean} 表示するか否か
         */
        checkZoomSwitchTarget: function (layerInfo, layer) {
            var isTarget = false;
            // layerがzoomLevelを持っていればlayerのoptionsに設定する
            if (//layerInfo.infoCategoryCd === 'D100' || // 被害報告
                //layerInfo.infoCategoryCd === 'D101' || // 通行規制
                layerInfo.infoCategoryCd === 'D108' && // 作図
                layerInfo.minZoom) {
                if (!layer.options) {
                    layer.options = {
                        minZoom: layerInfo.minZoom,
                        isOutOfZoom: false
                    };
                } else {
                    layer.options.minZoom = layerInfo.minZoom;
                    layer.options.isOutOfZoom = false;
                }
            }
            if (this.map.getZoom() < layerInfo.minZoom && layer.options.minZoom) {
                layer.options.isOutOfZoom = true;
                isTarget = true;
            }
            return isTarget;
        },

        /**
         * zoomLevelを監視して、レイヤーの表示非表示を切り替える。
         */
        zoomLevelManagement: function () {
            Object.keys(this.layers).forEach(lang.hitch(this, function (id) {
                var layer = this.layers[id];
                var layerOptions = layer.options;
                var zoomLevel = this.map.getZoom();
                if (//layerOptions.infoCategoryCd === 'D100' ||  // 被害報告
                    //layerOptions.infoCategoryCd === 'D101' ||  // 通行規制
                    layerOptions.infoCategoryCd === 'D108') { // 作図
                    if (layerOptions.minZoom) {
                        // 非表示
                        if (layerOptions.minZoom > zoomLevel && this.map.hasLayer(layer)) {
                            this.map.removeLayer(layer);
                            this.removeArrowHeadFromMap(layer);
                            layer.options.isOutOfZoom = true;
                        }
                        // 表示
                        if (layerOptions.minZoom <= zoomLevel && !this.map.hasLayer(layer)) {
                            this.map.addLayer(layer);
                            this.addArrowHead2Map(layer);
                            layer.options.isOutOfZoom = false;
                        }
                    }
                }
            }));
        },

        /**
         *
         * @param {*} url
         */
        addLayerWithInfoCategoryAndStyle: function (layerModule, layerInfo, opacity, additionalFunc) {
            return all({
                // layerInfoがstyleを持っている場合は取得
                style: layerInfo.styleUrl && this._getStyle(_parseUrl(layerInfo.styleUrl, layerInfo.id))

            }).then(lang.hitch(this, function (result) {
                var options = result.style && result.style.geojsonOptions;

                var layer = new layerModule(layerInfo, options);
                this.layers[layerInfo.id] = layer.addTo(this.map);
                // 透過度の設定
                this.layers[layerInfo.id].options.opacity = opacity;
                // 情報種別の設定
                this.layers[layerInfo.id].options.infoCategoryCd = layerInfo.infoCategoryCd;

                // 引数で指定された追加処理を実施
                if (additionalFunc) {
                    additionalFunc.call(this, {
                        result: result,
                        layer: layer
                    });
                }

                // 前面表示
                this.toFront(layerInfo.id);
            }));
        },

        /**
         * 道路交通規制情報を取得して、geojson形式にして返す
         */
        getRegulationGeojson: function () {
            var deferred = new Deferred();
            Requester.get('/api/traffic/import').then(function (listData) {
                var features = [];
                array.forEach(listData, function (data) {
                    // GEOJSONの形式で格納
                    features.push({
                        type: 'Feature',
                        properties: {
                            id: data.id,
                            category: data.category,
                            road: data.road,
                            roadDivision: data.roadDivision,
                            roadName: data.roadName,
                            kind: data.kind,
                            kindName: data.kindName,
                            address: data.address,
                            reason: data.reason,
                            period: data.period,
                            disasterFlg: !data.dateEnd,
                            management: data.management,
                            note: data.note,
                            area: data.area
                        },
                        geometry: {
                            type: 'Point',
                            coordinates: [data.lng, data.lat]
                        }
                    });
                });
                deferred.resolve({
                    type: 'FeatureCollection',
                    features: features
                });
            });
            return deferred.promise;
        }
    });
});
