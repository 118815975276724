/**
 * 避難所情報のユーティリティー関数群。
 * @module app/shelter/ShelterUtils
 */
 define([
    'module',
    'dojo/date',
    'dojo/Deferred',
    'dojo/_base/lang',
    'idis/util/DateUtils',
    'app/AppUtils',
    'idis/service/Requester',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'app/model/DisasterInfo',
    'app/config'
], function(module, date, Deferred, lang, DateUtils, AppUtils, Requester, Loader, DialogChain,
    UserInfo, USER_TYPE, DisasterInfo, config) {
    /**
     * 指定された要素が開設中か否かをチェックする関数を返す。
     * 開設中の条件：
     * 『開設日時があり開設日時が基準日時よりも前かつ閉鎖日時がない』または閉鎖日時が基準日時よりも後
     * @param {Date} target 基準日時
     * @returns {function} 指定された要素が開設中か否かをチェックする関数
     */
   function _isOpenOn() {
       return function(item) {
           if (AppUtils.isEmpty(item.shelterStartTimestamp)) {
               // 開設日時の入力がない
               return false;
           }
           if (AppUtils.isEmpty(item.shelterEndTimestamp)) {
               // 閉鎖日時の入力がない
               return true;
           }
           return false;
       };
    }

    /**
     * 指定されたユーザが市・組織所属の一般職員かをチェックする関数を返す。
     * 検知対象ユーザ
     * - 一般職員/応援職員/自主防災組織/福祉避難所の市所属ユーザ
     * @param {object} UserInfo ユーザ情報
     * @returns {boolean} 指定されたユーザが市・組織所属の一般職員かどうか
     */
     function _isCityNormalUser(UserInfo) {
        var rCd = UserInfo.getRoleCd();
        var mCd = UserInfo.getMunicipalityCd();
        var TARGET_ROLE_CDS = ['R03012', 'R03022'];

        // 市所属以外はfalse
        if (mCd !== config.municInfo.cityMunicCd) {
            return false;
        } else {
            if (UserInfo.getUserType() === USER_TYPE.OTHER_ORGAN) {
                // 関係機関ユーザの場合はtrue
                return true;
            } else if
                (UserInfo.getUserType() === USER_TYPE.MUNICIPALITY &&
                TARGET_ROLE_CDS.indexOf(rCd) !== -1) {
                // 市区町村ユーザかつRoleCdが合致する場合はtrue
                return true;
            } else {
                return false;
            }
        }
    }

    /**
     * 指定されたユーザが本部確認可能かをチェックする関数を返す。
     * 管理ユーザ、全体管理者、市本部ユーザおよび区本部ユーザ（自区情報のみ）が本部確認可能。
     * @param {object} UserInfo ユーザ情報
     * @param {String} municipalityCd 遷移元の本部確認対象itemの市区コード
     * @returns {function} 指定されたユーザが本部確認可能かをチェックする関数
     */
    function _isHeadQuarterUser(UserInfo, municipalityCd) {
        var rCd = UserInfo.getRoleCd();
        var mCd = UserInfo.getMunicipalityCd();
        // 本部確認可能ユーザ：管理ユーザ、全体管理者、市本部ユーザ、区本部ユーザ
        var HEADQUARTERS_USERS = ['R01001', 'R01011', 'R02012', 'R02022'];
        // 区本部ユーザのみ挙動が異なる（自区情報のみ）ので個別に定数定義する
        var WARD_HEADQUARTERS_USER = 'R02022';

        if (HEADQUARTERS_USERS.indexOf(rCd) === -1) {
            // 本部確認可能ユーザ以外はfalse
            return false;
        }
        if (HEADQUARTERS_USERS.indexOf(rCd) !== -1 && rCd !== WARD_HEADQUARTERS_USER) {
            // R01001: 管理ユーザ、R01011：全体管理者、R02012：市本部ユーザはtrue
            return true;
        } else if (rCd === WARD_HEADQUARTERS_USER && (municipalityCd === mCd || !municipalityCd)) {
            // R02022：区本部ユーザかつ呼び出し元の市区コードと一致すればtrue
            // 概況・一覧の場合は、市区コードは関係なし（自動連携モードボタン用の判定になる）
            return true;
        }
        return false;
    }

    /**
     * 指定されたユーザが自動承認機能の利用権限を持つかチェックする関数を返す。
     * 区本部ユーザのみが自動承認操作可能。
     * @param {object} UserInfo ユーザ情報
     * @returns {function} 指定されたユーザが自動承認利用可能かをチェックする関数
     */
     function _hasAutoConfirmAuthz(UserInfo) {
        var rCd = UserInfo.getRoleCd();
        var AUTHZ_USERTYPE = ['R02022'];

        if (AUTHZ_USERTYPE.indexOf(rCd) !== -1) {
            // R02022：区本部ユーザであればtrue
            return true;
        }
        return false;
    }

    /**
     * 自動承認ボタン押下時の処理
     *   1. 溜まっている本部未確認ステータスの報を配信する
     *   2. 自動承認レコードを登録する（pram: disasterId, municipalityCd)
     *   3. return promise.resolve()
     */
    function _startAutoConfirm(chain) {
        // var deferred = new Deferred();
        // 必要パラメータの準備（municipalityCd, disasterId)
        var municipalityCd = UserInfo.getMunicipalityCd();
        var disasterId = DisasterInfo.getDisasterId();
        if(!disasterId) {
            disasterId = 1;
            console.warn('災害IDが設定されていません。');
        }
        // 自動承認レコードを登録する（pram: disasterId, municipalityCd)
        console.log('自動承認モードを開始します: {' + disasterId + ', ' + municipalityCd + '}');
        // リクエストの発行からレスポンス受信後の処理までをpromiseに格納
        var promise = Requester.post('/api/shelters/autoConfirm', {
            query: {
                disasterId: disasterId,
                municipalityCd: municipalityCd
            }
        }).then(function(){
            // 処理成功時は完了ダイアログを表示
            console.log('自動承認モードを開始しました');
            var message = '自動承認モードを開始しました。';
            chain.info(message, '完了');
            // deferred.resolve();
        }, function(err) {
            // 処理失敗時はエラーログを出力
            console.error(err);
            chain.infoError('自動承認モード開始に失敗しました。', 'エラー');
            // deferred.rejected();
        });
        // ローダーの表示
        Loader.wait(promise);
        // ボタン表示等制御のためプロミスを返却
        // return deferred.promise;
    }

    /**
     * 自動承認解除ボタン押下時の処理
     *   1. 自動承認レコードを論理削除する（pram: disasterId, municipalityCd)
     */
     function _endAutoConfirm(chain) {
        // 必要パラメータの準備（municipalityCd, disasterId)
        var municipalityCd = UserInfo.getMunicipalityCd();
        var disasterId = DisasterInfo.getDisasterId();
        if(!disasterId) {
            disasterId = 1;
            console.warn('災害IDが設定されていません。');
        }
        // 1. 自動承認レコードを登録する（pram: disasterId, municipalityCd)
        // 自動承認状況削除APIを叩く
        console.log('自動承認モードを解除します: {' + disasterId + ', ' + municipalityCd + '}');
        // リクエストの発行からレスポンス受信後の処理までをpromiseに格納
        var promise = Requester.del('/api/shelters/autoConfirm', {
            query: {
                disasterId: disasterId,
                municipalityCd: municipalityCd
            }
        }).then(function(){
            // 処理成功時は完了ダイアログを表示
            console.log('自動承認モードを解除しました');
            var message = '自動承認モードを解除しました。';
            chain.info(message, '完了');
        }, function(err) {
            // 処理失敗時はエラーログを出力
            console.error(err);
            chain.infoError('自動承認モード解除に失敗しました。', 'エラー');
        });
        // ローダーの表示
        Loader.wait(promise);
    }

    /**
     * 自動承認ステータスを取得する処理
     * @param mCd 市区コード(municipalityCd)
     *            明示的に引数が指定されている場合、渡された値を使用する
     * @return {Promise}
     */
    function _isAutoConfirmed(mCd) {
        var deferred = new Deferred();
        // 必要パラメータの準備（municipalityCd, disasterId)
        // 引数で市区コードの指定がある場合はそちらを使用する
        var municipalityCd = mCd ? mCd : UserInfo.getMunicipalityCd();
        var disasterId = DisasterInfo.getDisasterId();
        if(!disasterId) {
            disasterId = 1;
            console.warn('災害IDが設定されていません。');
        }
        // 承認状況リクエスト
        console.log('自動承認モード状況を照合: {' + disasterId + ', ' + municipalityCd + '}');
        var promise = Requester.get('/api/shelters/autoConfirm', {
            query: {
                disasterId: disasterId,
                municipalityCd: municipalityCd
            }
        }).then(lang.hitch(this, function(data){
            // 処理成功時
            console.log('自動承認モード状況を正常取得しました: ' + data );
            deferred.resolve(data);
        }), function(err) {
            // 処理失敗時
            console.error(err);
            deferred.rejected();
        });
        // ローダーの表示
        Loader.wait(promise);
        // ボタン表示等制御のためプロミスを返却
        return deferred.promise;
    }

    // long型のtimestampを、Lアラート連携時に使って要る形式に変える
    function _formatDate(longTime){
        var date = new Date(longTime);
        return _formatDateFromObj(date);
    }

    function _formatDateFromObj(date){
        return date.getFullYear() + '-' + _zeroPadding(date.getMonth() + 1) + '-' +
                    _zeroPadding(date.getDate()) + 'T' + _zeroPadding(date.getHours()) + ':' +
                    _zeroPadding(date.getMinutes()) + ':' + '00.000+09';
    }

    /**
     * 月や日付を2桁にゼロpaddingする
     */
    function _zeroPadding(month) {
        return ('00' + month).slice(-2);
    }

    return {
        isOpenOn: _isOpenOn,
        isCityNormalUser : _isCityNormalUser,
        isHeadQuarterUser : _isHeadQuarterUser,
        hasAutoConfirmAuthz : _hasAutoConfirmAuthz,
        startAutoConfirm : _startAutoConfirm,
        endAutoConfirm : _endAutoConfirm,
        isAutoConfirmed : _isAutoConfirmed,
        formatDate : _formatDate
    };
});
